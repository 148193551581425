import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@material-ui/core';
import LikeDislike from "../assets/img/like-dislike.svg";
import { Tx } from '../translate';
import { setEngagement } from '../redux/actions';
import { useDispatch } from 'react-redux';

const EngagementSurveyPortal = ({ redirectToOtherPage }) => {
  const dispatch = useDispatch();

  const redirectSurveyPage = () => {
    dispatch(setEngagement(null))
    redirectToOtherPage("/engagement-survey")
  }

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} className="ESP">
              <Box display="flex" sx={{ mb: 4.6, justifyContent: "Center" }} style={{ marginBottom: "74.8px" }}>
                <img src={LikeDislike} alt="" />
              </Box>
              <Typography className='TextAlignCenter' variant="h5">{" "} <Tx>engagement_survey_portal</Tx> {" "}</Typography>
              <Typography>
                {/* <Tx>engagement_survey_card_heading</Tx>{" "} */}
              </Typography>
              <Button
                variant="contained"
                className="GreenBtn"
                onClick={redirectSurveyPage}
              >
                <Tx>visit_engagement_survey_portal_button</Tx>{" "}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EngagementSurveyPortal;
