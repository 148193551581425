import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import LikeDislike from "../assets/img/like-dislike.svg";
import { Tx } from "../translate";
import { setEngagement } from "../redux/actions";
import { useDispatch } from "react-redux";

const EngagementSurveyCard = ({ engagements }) => {
  const dispatch = useDispatch()

  const checkCompletedAndNewSurvey = (newSurvey) => {
    return Object.keys(engagements)
      .sort()
      .findIndex(
        (key) =>
          key.search(/engagement/) > -1 &&
          !engagements[key]["last_response_time"] === newSurvey
      ) === -1;
  }

  return (
    <Box className="DashboardSheet">
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={6} className="ESP" align="center">
                <Box display="flex" sx={{ mb: 4.4, justifyContent: "Center" }}>
                  <img src={LikeDislike} alt="" />
                </Box>
                <Typography variant="h5">
                  {" "}
                  <Tx>engagement_survey_portal</Tx>
                </Typography>
                {/* <Typography>
                  <Tx>engagement_survey_card_heading</Tx>
                </Typography> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="OrangeBtnList">
                  <Typography component="h6" variant="h6"><Tx>your_new_survey</Tx></Typography>
                  <List
                    disablePadding
                    className="serviceList OrangeBt"
                    component="nav"
                    aria-label="mailbox folders"
                  >
                    {Object.keys(engagements).sort().map(
                      (key) =>
                        key.search(/engagement/) > -1 && !engagements[key]['last_response_time'] && (
                          <ListItem component="a" href="#" onClick={() => dispatch(setEngagement(engagements[key], key))}>
                            <ListItemText primary={engagements[key].title} />
                          </ListItem>
                        )
                    )}
                  </List>
                  {checkCompletedAndNewSurvey(true) && (
                    <span className="NotAvailable">
                      <Tx>not_new_survey_available</Tx>
                    </span>
                  )}
                </Box>
                <Typography component="h6" variant="h6"><Tx>your_completed_survey</Tx></Typography>
                <List
                  disablePadding
                  className="serviceList"
                  component="nav"
                  aria-label="mailbox folders"
                >
                  {Object.keys(engagements).sort().map(
                    (key) =>
                      key.search(/engagement/) > -1 && engagements[key]['last_response_time'] && (
                        <ListItem component="a" href="#" onClick={() => dispatch(setEngagement(engagements[key], key))}>
                          <ListItemText primary={engagements[key].title} />
                        </ListItem>
                      )
                  )}
                </List>
                {checkCompletedAndNewSurvey(false) && (
                  <span className="NotAvailable">
                    <Tx>not_complete_survey_available</Tx>
                  </span>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default EngagementSurveyCard;
