// Reference: https://github.com/vercel/next.js/tree/canary/examples/with-redux-thunk

import { combineReducers } from "redux";
import * as types from "./types";
import moment from "moment-timezone";

const initialState = {
  selectedSurvey: types.SURVEY_DIVERSITY,
  loadInProgress: false,
  errorMessage: null,
  organizationKey: null,
  safePorterIdLoggedIn: false,
  submitInProgress: false,
  engagement: null,
  reloadSUrveysLoading: false,
  onSideBar: false,
};

const appDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SURVEYS_INITIATED:
      return { ...state, loadInProgress: true };
    case types.FETCH_SURVEYS:
      console.log("reducing fetch")
      console.log(action.destination)
      return {
        ...state,
        spId: action.spId,
        trustMetricData: action.trustMetricData,
        trustMetrics: action.trustMetrics,
        trustMetricsValues: action.trustMetricsValues,
        trustMetricMethod: action.trustMetricMethod,
        surveys: action.surveys,
        diversitySurvey: action.surveys.diversity,
        feedbackSurvey: action.surveys.inclusion,
        destination: action.destination,
        surveyLoaded: true,
        surveySubmitted: false,
        loadInProgress: false,
        errorMessage: null,
        safePorterIdLoggedIn: true,
      };
    case types.REFETCH_SURVEYS_INITIATED:
      return { ...state, reloadSUrveysLoading: true };
    case types.ON_SIDEBAR:
      return { ...state, onSideBar: true };
    case types.RELOAD_FETCH_SURVEYS:
      return {
        ...state,
        surveys: action.surveys,
        reloadSUrveysLoading: false,
        diversitySurvey: action.surveys.diversity,
        feedbackSurvey: action.surveys.inclusion,
        trustMetrics: action.trustMetrics,
      };
    case types.SET_NEW_SURVEY_SUBMIT:
      return {
        ...state,
        surveySubmitted: false,
      };
    case types.FETCH_SURVEYS_ERROR:
      return {
        ...state,
        errorMessage: "Sorry, We could not find a survey for the provided ID",
        loadInProgress: false,
      };
    case types.RELOAD_FETCH_SURVEYS_ERROR:
      return {
        ...state,
        reloadSUrveysLoading: false,
        errorMessage: "Sorry, We could not find a survey for the provided ID",
      };
    case types.SUBMIT_SURVEYS_INITIATED:
      return { ...state, submitInProgress: true, errorMessage: '' };
    case types.SUBMIT_SURVEY:
      const data = { ...state }
      // Updated survey submit time to detect survey completion on thank you page without waiting for API response 
      if (action.surveyType.search(/engagement/) != -1) {
        const survey = data.surveys[action.surveyType];
        survey.last_response_time = moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS')
        data.surveys[action.surveyType] = survey;
      } else {
        const survey = data[action.surveyType];
        survey.last_response_time = moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS')
        data[action.surveyType] = survey;
      }
      return {
        ...data,
        surveySubmitted: true,
        submitInProgress: false,
      };
    case types.SUBMIT_SURVEY_ERROR:
      return {
        ...state,
        surveySubmitted: true,
        submitInProgress: false,
        errorMessage: "Sorry, We could not accept your survey.",
      };
    case types.SELECT_SURVEY:
      return {
        ...state,
        selectedSurvey: action.key,
        surveySubmitted: false,
      };
    case types.SET_ORGANIZATION_KEY:
      return {
        ...state,
        organizationKey: action.key,
      };
    case types.SET_SURVEY_COMPLETED_ON:
      return {
        ...state,
        diversitySurvey: {
          ...state.diversitySurvey,
          completedOn: action.payload,
        },
      };
    case types.SET_SURVEY_MODIFIED_ON:
      return {
        ...state,
        diversitySurvey: {
          ...state.diversitySurvey,
          lastModifiedOn: action.payload,
        },
      };
    case types.FETCH_TENANT_INITIATED:
      return {
        ...state,
        loadInProgress: true
      }
    case types.FETCH_TENANT:
      return {
        ...state,
        organizationKey: action.clientKey,
        tenant: action.data,
        loadInProgress: false,
        errorMessage: null
      };
    case types.FETCH_TENANT_ERROR:
      console.log("reducing")
      return {
        ...state,
        errorMessage: "Sorry, we can't find that code.",
        loadInProgress: false,
      };
    case types.SET_ENGAGEMENT:
      return {
        ...state,
        engagement: action.engagement,
        engagementKey: action.key,
        surveySubmitted: false,
      };
    default:
      return state;
  }
};

// COMBINED REDUCERS
const reducers = {
  appData: appDataReducer,
};

export default combineReducers(reducers);
