import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import withAuth from "../../components/withAuth";
import { useSelector } from "react-redux";
import EngagementSurveyCard from "../../components/EngagementSurveyCard";
import Engagement from "../engagement";

const styles = (theme) => ({
  root: {
    marginBottom: 4,
  },
  heading: {
    flexShrink: 0,
  },
  accordionHead: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  accordionBody: {
    border: `4px solid ${theme.palette.secondary.main}`,
  },
  accordionFoot: {
    // backgroundColor: theme.palette.secondary.main,
  },
  buttonPrimary: {
    color: "black",
  },
});

const EngagementSurvey = ({ classes }) => {
  const engagements = useSelector((state) => state.appData?.surveys);
  const engagement = useSelector((state) => state.appData?.engagement);
  return !engagement ? <EngagementSurveyCard engagements={engagements} /> : <Engagement engagement={engagement} />
};

export default withAuth(withStyles(styles)(EngagementSurvey));
