import React from "react"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"
import { Tx, tx, language_code } from "../../translate"
import CircularProgress from "@material-ui/core/CircularProgress";

import {
    Box,
    Button,
    Grid,
    TextField,
    Hidden,
    Typography,
    Link,
    withStyles,
} from "@material-ui/core"

import { ErrorMessage } from "@hookform/error-message"
import { useDispatch, useSelector } from "react-redux"
import DocumentTitle from "react-document-title"
import Footer from "../../components/Footer";
import { setOrganizationKey, getTenant } from "../../redux/actions"

const styles = (theme) => ({
    container: {
        display: "flex",
        height: "100%",
    },
    item: {
        height: "100%",
        overflowWrap: "anywhere",
    },
    vendorImage: {
        maxHeight: 100,
        width: "auto",
    },
    loginBox: {
        [theme.breakpoints.up("md")]: {
            borderLeft: `4px solid ${theme.palette.secondary.main}`,
        },
        [theme.breakpoints.down("sm")]: {
            borderTop: `4px solid ${theme.palette.secondary.main}`,
        },
    },
})

const Vendor = ({ classes }) => {
    const errorMessage = useSelector((state) => state.appData.errorMessage)
    const { register, handleSubmit, setError, errors } = useForm()
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.appData.loadInProgress);

    React.useEffect(() => {
        if (errorMessage != null) {
            setError("clientKey", {
                type: "manual",
                message: errorMessage,
            })
        }
    }, [errorMessage])

    const onSubmit = (data) => {
        const clientKey = escape(data.clientKey.toLowerCase().replace(/\s/g, ""))
        console.log({ clientKey })
        dispatch(getTenant(clientKey))
    }

    return (
        <DocumentTitle title="SafePorter - Login to Organization">
            <Grid className={classes.container} container spacing={3}>
                <Box
                    clone
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            p={2}
                            pt={6}
                        >
                            <section aria-label="about SafePorter">
                                <Box clone pb={2}>
                                    <Typography
                                        component="p"
                                        // align="center"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>commited_to_protect</Tx>
                                    </Typography>
                                </Box>

                                <Box clone pb={2}>
                                    <Typography
                                        component="p"
                                        // align="center"
                                        color="primary"
                                        variant="h5"
                                        gutterBottom
                                    >
                                        <Tx>your_info</Tx>
                                    </Typography>
                                </Box>

                                <Box clone pb={2}>
                                    <Typography
                                        component="p"
                                        // align="center"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <strong>
                                            <Tx>spid_tied_to</Tx>
                                        </strong>{" "}
                                        <Tx>change_any_time</Tx>
                                    </Typography>
                                </Box>
                                <Hidden smDown>
                                    <Typography align="center" gutterBottom>
                                        <Link
                                            href="https://www.safeportersecure.com/privacynotice"
                                            color="inherit"
                                            underline="always"
                                            target="_blank"
                                        >
                                            <Tx>lbl_privacy_policy</Tx>
                                        </Link>
                                        &nbsp;<span>&middot;</span>&nbsp;
                                        <Link
                                            href="https://safeportersecure.com/"
                                            color="inherit"
                                            underline="always"
                                            target="_blank"
                                        >
                                            SafePorter
                                        </Link>
                                    </Typography>
                                </Hidden>
                            </section>
                        </Box>
                    </Grid>
                </Box>
                <Box
                    clone
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            p={2}
                            pt={6}
                            className={classes.loginBox}
                        >
                            <section aria-label="login to your organization">
                                <Typography
                                    variant="h3"
                                    component="h1"
                                    align="center"
                                    color="primary"
                                    gutterBottom
                                >
                                    <Tx>representation_is_critical</Tx>
                                </Typography>
                                <Box clone pb={6}>
                                    <Typography
                                        variant="subtitle1"
                                        component="p"
                                        align="center"
                                        color="textSecondary"
                                        gutterBottom
                                    >
                                        <Tx>your</Tx> Data<strong>Protected</strong>{" "}
                                        <Tx>ensures</Tx>
                                    </Typography>
                                </Box>
                                {loading && (
                                    <Box mb={4} display="flex" justifyContent="center">
                                        <CircularProgress color="primary" />
                                    </Box>
                                )}
                                <form
                                    autoComplete="on"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <TextField
                                        id="organziation-key"
                                        label={tx('organization_key')}
                                        variant="outlined"
                                        fullWidth
                                        inputRef={register({
                                            required: "Please enter a Key",
                                        })}
                                        name="clientKey"
                                        error={!!errors.clientKey}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="clientKey"
                                        render={({ message }) => (
                                            <Typography color="error">
                                                {message}
                                            </Typography>
                                        )}
                                    />
                                    <Box mt={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={loading}
                                        >
                                            <Tx>submit_key</Tx>
                                        </Button>
                                    </Box>
                                </form>
                                <Box mt={3}>
                                    <Typography
                                        variant="caption"
                                        component="p"
                                        align="center"
                                        color="textSecondary"
                                        gutterBottom
                                    >
                                        <Tx>b_corp</Tx>
                                    </Typography>
                                </Box>
                                <Hidden mdUp>
                                    <Typography align="center" gutterBottom>
                                        <Link
                                            href="https://www.safeportersecure.com/privacynotice"
                                            color="inherit"
                                            underline="always"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </Link>
                                        &nbsp;<span>&middot;</span>&nbsp;
                                        <Link
                                            href="https://safeportersecure.com/"
                                            color="inherit"
                                            underline="always"
                                            target="_blank"
                                        >
                                            SafePorter
                                        </Link>
                                    </Typography>
                                </Hidden>
                            </section>
                        </Box>
                    </Grid>

                </Box>
                <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                    <Footer />
                </div>


            </Grid>

        </DocumentTitle>
    )
}

Vendor.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Vendor)

