import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#138e8b",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#fa6c51",
    },
    error: {
      main: "#D62C0A",
    },
    warning: {
      main: "#ef8c25",
    },
    info: {
      main: "#70d9b8",
    },
    success: {
      main: "#6cbf84",
    },
    white: {
      main: "#ffffff",
    },
    gray: {
      main: "#8A8A8A",
    },
  },
  typography: {
    fontFamily: ["Lato", "Arial"].join(","),
  },
});

export default theme;
