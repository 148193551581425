import React from "react"
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core"
import { tx } from "../translate"

const NoAnswerCheckboxComponent = ({
    title,
    name,
    handleCheck,
    setIsNoAnswerSelected,
    isNoAnswerSelected,
    props,
}) => {
    return (
        <Grid item xs={12} md={6} key={`${title}_NoAnswer`}>
            <FormControlLabel
                value="NoAnswer"
                control={
                    <Checkbox
                        checked={isNoAnswerSelected}
                        color="primary"
                        name="NoAnswer"
                        onChange={(e) => {
                            props.onChange(
                                handleCheck(
                                    "NoAnswer",
                                    name,
                                    e.target.checked ? true : false
                                )
                            )
                            setIsNoAnswerSelected(e.target.checked)
                        }}
                    />
                }
                label={tx("prefer_no_answer")}
            />
        </Grid>
    )
}

export { NoAnswerCheckboxComponent }
