import React from "react"
import {
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Tooltip,
    withStyles,
} from "@material-ui/core"
import Grid from '@mui/material/Grid';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Controller } from "react-hook-form"
import { AllyComponent } from "./AllyComponent"
import { NoAnswerCheckboxComponent } from "./NoAnswerCheckboxComponent"

const styles = {
    tooltipSpan: {
        display: "flex",
    },
    tooltip: {
        color: "lightblue",
        backgroundColor: "green",
    },
    mobileSliderBox: {
        minHeight: "130px",
    },
}

const BlackTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "black",
        fontSize: "1em",
    },
    arrow: {
        color: "black",
    },
})(Tooltip)

const HelpTooltip = withStyles(styles)(({ desc, classes }) => (
    <span className={classes.tooltipSpan}>
        <BlackTooltip title={desc} arrow enterTouchDelay={0}>
            <HelpOutlineOutlinedIcon sx={{ color: '#8a8a8a' }} />
        </BlackTooltip>
    </span>
))

const SelectManyComponent = ({
    title,
    name,
    hookFormControl,
    isRequired,
    rawset,
    setIsNoAnswerSelected,
    isNoAnswerSelected,
    handleCheck,
    addRemoveCheckboxValues,
    isChecked,
    nameOfChecked,
}) => {
    return (
        <Controller
            rules={{
                required: isRequired && `Please select a value for ${title}`,
            }}
            control={hookFormControl}
            name={name}
            defaultValue={[]}
            render={(props) => (
                <FormGroup aria-label={`checkbox options for ${title}`}>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {rawset
                            .filter((option) => !option.ally)
                            .map((option) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    key={`${title}_${option.title}`}
                                >
                                    <Box className="CheckboxList" display="flex" alignItems="center">
                                        <FormControlLabel
                                            value={option.key}
                                            control={
                                                <Checkbox
                                                    checked={isChecked(
                                                        option.title
                                                    )}
                                                    disabled={isNoAnswerSelected}
                                                    color="primary"
                                                    name={option.title}
                                                    onChange={(e) => {
                                                        props.onChange(
                                                            handleCheck(
                                                                option.key,
                                                                name
                                                            )
                                                        )
                                                        addRemoveCheckboxValues(
                                                            e.target.checked,
                                                            e.target.name
                                                        )
                                                    }}
                                                />
                                            }
                                            label={option.title}
                                        />
                                        {option?.description?.length > 0 && (
                                            <HelpTooltip
                                                desc={option?.description}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            ))}
                        <NoAnswerCheckboxComponent
                            title={title}
                            name={name}
                            handleCheck={handleCheck}
                            isNoAnswerSelected={isNoAnswerSelected}
                            setIsNoAnswerSelected={setIsNoAnswerSelected}
                            props={props}
                        />

                        {rawset
                            .filter((option) => option.ally)
                            .map((option, index) => (
                                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-4" key={index}>
                                    <AllyComponent
                                        title={title}
                                        name={name}
                                        isNoAnswerSelected={isNoAnswerSelected}
                                        handleCheck={handleCheck}
                                        addRemoveCheckboxValues={
                                            addRemoveCheckboxValues
                                        }
                                        isChecked={isChecked}
                                        nameOfChecked={nameOfChecked}
                                        option={option}
                                        props={props}
                                    />
                                </div>
                            ))}
                    </Grid>
                </FormGroup>
            )}
        />
    )
}

export { SelectManyComponent }
