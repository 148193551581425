import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorIcon from "@material-ui/icons/Error";

import DocumentTitle from "react-document-title";
import moment from "moment-timezone";

import { SPFormFieldset, fieldNameClean } from "../components/CustomForm";
import WelcomeBackView from "../components/WelcomeBackView";
import { submitSurvey } from "../redux/actions";

import withAuth from "../components/withAuth";
import AcceptTrustMetricDialog from "../components/AcceptTrustMetricDialog";
import ThankYouPage from "./ThankYou";
import { Tx, tx } from "../translate";
import AppContext from "../components/AppContext";

const MOMENT_FORMAT = "MMMM Do, YYYY, h:mm A (z)";
const styles = (theme) => ({
  heading: {
    flexShrink: 0,
  },
  accordionHead: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  accordionMain: {
    border: `4px solid ${theme.palette.info.main}`,
  },
  accordionBody: {
    flexDirection: "column",
  },
  formTitle: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  svg: {
    marginRight: 8,
    verticalAlign: "text-top",
  },
  accordionFoot: {
    margin: '0 20px 20px'
  }
});

function getSelectedValues(category, survey) {
  if (
    category.options.some((option) => option.multi_racial || option.non_binary)
  ) {
    return (
      (survey[category.key]?.selectedValues ?? survey[category.key]?.value) ||
      null
    );
  }
  if (category.select === "search") {
    return survey[category.key].map((option) => option.value);
  } else {
    return survey[category.key];
  }
}

const Surveys = ({ classes, setIsDiversityDisabled }) => {
  const userSettings = useContext(AppContext)
  const surveySubmitted = useSelector((state) => state.appData.surveySubmitted);
  const [deleteSubmitted, setDeleteSubmitted] = React.useState(false);
  const [value, setValue] = React.useState('');
  const whichSurvey = useSelector((State) => State.appData.selectedSurvey);
  const api = useSelector((state) => state.appData.destination);
  const surveyDiversity = useSelector((state) => state.appData.diversitySurvey);
  const formCompletedOn = useSelector(
    (state) => state.appData.diversitySurvey.last_response_time
  );
  // If formCompletedOn is not a valid date, all fields are mandatory (first submission)
  const areFieldsRequired =
    formCompletedOn === undefined || formCompletedOn === "";
  const formLastModifiedOn = useSelector(
    (state) => state.appData.diversitySurvey.lastModifiedOn
  );
  const errorMessage = useSelector((state) => state.appData.errorMessage);
  const submissionInProgress = useSelector(
    (state) => state.appData.submitInProgress
  );
  const spId = useSelector((state) => state.appData.spId);
  const tenant_id = useSelector((State) => State.appData.tenant.tenant_id)
  const trustMetricData = useSelector((state) => state.appData.trustMetricData);
  const trustMetricsFlag = useSelector((state) => state.appData.trustMetrics);
  // const haveAnswers = !!spId.split(".")[1];
  const haveAnswers = !!trustMetricData;
  const trustMetricMethod = useSelector(
    (state) => state.appData.trustMetricMethod)
  const [isSubmittingDiversity, setIsSubmittingDiversity] = React.useState(
    false
  );
  const [expanded, setExpanded] = React.useState(false);
  const [showSurvey, setShowSurvey] = React.useState(false);
  const [scrolledToTop, setScrolledToTop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [allSelectedValues, setAllSelectedValues] = React.useState([]);
  const [currentScrollIndex, setCurrentScrollIndex] = React.useState(0);
  const [hasNotChangedAtLeastOneField, setHasNotChangedAtLeastOneField] =
    React.useState(false);
  const [trustMetrics, setTrustMetrics] = React.useState("");
  const {
    control,
    handleSubmit,
    errors,
    setError,
    getValues,
    formState,
    clearErrors,
  } = useForm();
  const formSection = React.useRef(null);
  const errorDetails = Object.entries(errors);
  const dispatch = useDispatch();

  const scrollToElement = (selector) => {
    const element = document.querySelector(selector);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const openNext = () => {
    // Open next error fieldset
    if (errorDetails.length > 0) {
      setExpanded((prevProp) => {
        const nextError = surveyDiversity.categories.indexOf(
          surveyDiversity.categories.find(
            (category, cIndex) => cIndex > prevProp && !!errors[category.key]
          )
        );
        if (nextError !== -1) {
          return nextError;
        }
        const someError = surveyDiversity.categories.indexOf(
          surveyDiversity.categories.find((category) => !!errors[category.key])
        );
        return someError;
      });
    } else {
      const formValues = getValues();
      const selectedValues = [];
      const response = { ...surveyDiversity };
      response.categories.forEach((category, cIndex) => {
        // Update the category with selectedValue
        category.selectedValue = getSelectedValues(category, formValues);
        // Push an object with the selected value and its index
        selectedValues.push({
          value: category.selectedValue,
          index: cIndex,
        });
        setAllSelectedValues(selectedValues);
        // Scroll to the next selected value
        if (
          selectedValues.length > 0 &&
          currentScrollIndex < selectedValues.length
        ) {
          const selectedValue = selectedValues[currentScrollIndex];
          const elementToScrollTo = document.getElementById(
            `panel-surveyDiversity-${selectedValue.index}-accordion`
          );
          if (elementToScrollTo) {
            elementToScrollTo.scrollIntoView({
              behavior: "auto",
              block: "start",
            });
          }
          // Update the current scroll index for the next scroll
          setCurrentScrollIndex(currentScrollIndex + 1);
        }
      });
      setExpanded((prevProp) => prevProp + 1);
    }
  };

  // Accessibility: focus on form on initial load
  React.useEffect(() => {
    if (showSurvey) {
      formSection?.current?.focus();
    }
  }, [formSection, showSurvey]);

  // Scroll error details into view
  React.useEffect(() => {
    if (errorDetails.length > 0 && !scrolledToTop) {
      scrollToElement("header");
      setScrolledToTop(true);
    }
  }, [errorDetails, scrolledToTop]);

  // Scroll to top on page load
  React.useEffect(() => {
    scrollToElement("header");
  }, []);

  const onSubmit = React.useCallback(
    (survey) => {
      // TODO: cleanup and move to a separate api independent of redux store structure
      const response = { ...surveyDiversity };
      response.categories = response.categories.map((category) => ({
        ...category,
        selectedValue: getSelectedValues(category, survey),
      }));
      response.trust_metrics = trustMetrics;

      const hasChangedAtLeastOneField = response.categories.some(
        (x) =>
          ((x.select === "many" || x.select === "search") &&
            x.selectedValue !== null &&
            x.selectedValue &&
            x.selectedValue.length !== 0) ||
          (x.select === "one" && !!x.selectedValue)
      );

      const hasNotChangedAllField = response.categories.some(
        (x) =>
          ((x.select === "many" || x.select === "search") &&
            (x.selectedValue === null ||
              (x.selectedValue && x.selectedValue.length === 0))) ||
          (x.select === "one" && x.selectedValue === null)
      );

      if (!areFieldsRequired && !hasChangedAtLeastOneField) {
        response.categories.map((category) =>
          setError(category.key, {
            type: "manual",
          })
        );
      }

      if (!areFieldsRequired && !hasChangedAtLeastOneField) {
        setHasNotChangedAtLeastOneField(true);
        return;
      }

      if (areFieldsRequired && hasNotChangedAllField) {
        return;
      }
      dispatch(submitSurvey(response, api, {
        spId,
        tenant_id,
        language: userSettings.language
      }, 'diversitySurvey'));
      setIsSubmittingDiversity(true);
      setIsDiversityDisabled(true);
    },
    [
      hasNotChangedAtLeastOneField,
      submitSurvey,
      areFieldsRequired,
      errors,
      setError,
      trustMetrics,
    ]
  );

  const handleChange = (panel) => (event, isExpanded) => {
    const formValues = getValues();
    const selectedValues = [];
    const response = { ...surveyDiversity };
    response.categories.forEach((category, cIndex) => {
      // Update the category with selectedValue
      category.selectedValue = getSelectedValues(category, formValues);
      // Push an object with the selected value and its index
      selectedValues.push({
        value: category.selectedValue,
        index: cIndex,
      });
      setAllSelectedValues(selectedValues);
    });
    setExpanded(isExpanded ? panel : false);
  };

  const handleDeleteAllClick = () => {
    setOpen(true);
  };

  const onDelete = React.useCallback((sp_id) => {
    const response = { ...surveyDiversity, delete_my_data: true };
    dispatch(submitSurvey(response, api));
    setDeleteSubmitted(true);
    setOpen(false);
    setIsDiversityDisabled(true);
  }, []);

  // TODO: handle delete confirm click
  const handleClose = () => {
    setOpen(false);
  };

  const arrayFields = surveyDiversity.categories
    .filter((category) => category.select === "many")
    .map((x) => x.key);

  React.useEffect(() => {
    allSelectedValues.map((data) => {
      if (data?.value?.length) {
        var element = document.getElementById(
          `panel-surveyDiversity-${data.index}-accordion`
        );
        element.classList.add("FilledAccordion");
      }
    });
  }, [allSelectedValues]);

  React.useEffect(() => {
    if (areFieldsRequired) {
      if (!formState.isSubmitted) {
        return;
      }

      const values = getValues(arrayFields);

      for (const fieldName in values) {
        const hasValue = values[fieldName] && values[fieldName].length > 0;
        const hasError = formState.errors.hasOwnProperty(fieldName);

        if (!hasValue) {
          if (!hasError) {
            setError(fieldName, { type: "manual" });
          }
        } else {
          if (hasError) {
            clearErrors(fieldName);
          }
        }
      }
    }
  }, [formState.isSubmitted, getValues(arrayFields)]);

  // Display trust metric popup only if user has not accepted the consent and answers are available
  if (trustMetricMethod === 'Accept' && trustMetricsFlag !== 'verified' && haveAnswers && !showSurvey) {
    return <AcceptTrustMetricDialog setTrustMetrics={setTrustMetrics} setShowSurvey={setShowSurvey} answers={trustMetricData} />
  }

  if (
    !showSurvey &&
    formCompletedOn &&
    moment(formCompletedOn).isValid() &&
    !deleteSubmitted
  ) {
    return (
      <WelcomeBackView
        formCompletedOn={formCompletedOn}
        setShowSurvey={setShowSurvey}
        onDelete={onDelete}
      />
    );
  }

  if ((surveySubmitted && isSubmittingDiversity) || deleteSubmitted) {
    return <ThankYouPage selectedSurvey={whichSurvey} />;
  }

  return (
    <DocumentTitle title="MyDiversity Survey">
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={10} xl={11}>
          <section
            id="my-diversity-form-section"
            aria-label="DIVERSITY IDENTITY SURVEY form"
            ref={formSection}
          >
            <Box className="pageHeading">
              <Typography variant="h2" component="h2">
                <Tx>d_and_i_survey</Tx>
              </Typography>
            </Box>
            {formCompletedOn && moment(formCompletedOn).isValid() && (
              <Box className="pageHeading">
                <Typography>
                  <Tx>completed_on</Tx>:{" "}
                  {moment(formCompletedOn)
                    .tz(moment.tz.guess(true))
                    .format(MOMENT_FORMAT)}
                </Typography>
              </Box>
            )}
            {formLastModifiedOn && moment(formLastModifiedOn).isValid() && (
              <Box className="pageHeading">
                <Typography>
                  <Tx>last_modified_on</Tx>:{" "}
                  {moment(formLastModifiedOn)
                    .tz(moment.tz.guess(true))
                    .format(MOMENT_FORMAT)}
                </Typography>
              </Box>
            )}

            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Box className="NumberAccordion">
                {surveyDiversity.categories.map((category, cIndex) => (
                  <Accordion
                    key={category.key}
                    expanded={expanded === cIndex}
                    onChange={handleChange(cIndex)}
                    square={false}
                    id={`panel-surveyDiversity-${cIndex}-accordion`}
                    className="ThemeAccordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-surveyDiversity-${cIndex}-content`}
                      id={`panel-surveyDiversity-${cIndex}-header`}
                    >
                      <Typography
                        className={classes.heading}
                        component="h2"
                        variant="h6"
                      >
                        {errors[category.key] && (
                          <ErrorIcon className={classes.svg} />
                        )}
                        {category.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionBody}>
                      <Box className="ACFormContent">
                        <Typography component="span" className="ACLabel">
                          {category.instructions}
                        </Typography>
                        <SPFormFieldset
                          options={category.options}
                          select={category.select}
                          title={category.title}
                          name={category.key}
                          key={`diversitySurvey-${category.key}`}
                          hookFormControl={control}
                          isRequired={areFieldsRequired}
                          getValues={getValues}
                        />
                      </Box>
                    </AccordionDetails>
                    {/* <Divider /> */}
                    <AccordionActions className={classes.accordionFoot}>
                      <Button
                        variant="contained"
                        className="OrangeBtn"
                        onClick={openNext}
                      >
                        <Tx>next</Tx>
                      </Button>
                    </AccordionActions>
                  </Accordion>
                ))}
              </Box>

              {/* Form api submission error */}
              {/* {!!errorMessage && (
                <DocumentTitle title={tx("error_submit_form")}>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    role="alert"
                  >
                    <Typography color="error">{errorMessage}</Typography>
                  </Box>
                </DocumentTitle>
              )} */}
              {/* Form validation errors */}

              {(errorDetails.length > 0 || hasNotChangedAtLeastOneField) && (
                <SubmitErrorBox areFieldsRequired={areFieldsRequired} />
              )}
              {submissionInProgress && (
                <Box mb={2} mt={2} display="flex" justifyContent="center">
                  <CircularProgress color="secondary" id="sp-submit-spinner" />
                </Box>
              )}
              <Box
                mt={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={submissionInProgress}
                >
                  <b>{tx("submit")}</b>
                </Button>
              </Box>
            </form>
          </section>
        </Grid>

        <Grid item xs={12} md={10}>
          <Box mt={3} display="flex" justifyContent="flex-end">
            {" "}
            {formCompletedOn && moment(formCompletedOn).isValid() && (
              <Button size="small" onClick={handleDeleteAllClick}>
                <Tx>delete_my_info</Tx>
              </Button>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Tx>delete_my_info</Tx>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Tx>i_confirm</Tx>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                  <Tx>cancel</Tx>
                </Button>
                <Button onClick={onDelete} variant="outlined" autoFocus>
                  <Tx>yes_i_confirm</Tx>
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </DocumentTitle>
  );
};

Surveys.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SubmitErrorBox = ({ areFieldsRequired }) => {
  return (
    <DocumentTitle title={tx("error_submit_form")}>
      <Box
        mt={1}
        mb={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        role="alert"
      >
        <Typography color="error" variant="h6">
          {!areFieldsRequired ? tx("please_one") : tx("please_all")}
        </Typography>
      </Box>
    </DocumentTitle>
  );
};
export default withAuth(withStyles(styles)(Surveys));
