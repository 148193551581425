export const FETCH_SURVEYS_INITIATED = "FETCH_SURVEYS_INITIATED";
export const REFETCH_SURVEYS_INITIATED = "REFETCH_SURVEYS_INITIATED";
export const FETCH_SURVEYS = "FETCH_SURVEYS";
export const FETCH_SURVEYS_ERROR = "FETCH_SURVEYS_ERROR";
export const SUBMIT_SURVEYS_INITIATED = "SUBMIT_SURVEYS_INITIATED";
export const SUBMIT_SURVEY = "SUBMIT_SURVEY";
export const SUBMIT_SURVEY_ERROR = "SUBMIT_SURVEY_ERROR";
export const FETCH_TENANT_INITIATED = "FETCH_TENANT_INITIATED";
export const FETCH_TENANT = "FETCH_TENANT"
export const FETCH_TENANT_ERROR = "FETCH_TENANT_ERROR"
export const SELECT_SURVEY = "SELECT_SURVEY";
export const RELOAD_FETCH_SURVEYS = "RELOAD_FETCH_SURVEYS";
export const RELOAD_FETCH_SURVEYS_ERROR = "RELOAD_FETCH_SURVEYS_ERROR";

export const SURVEY_DIVERSITY = "SURVEY_DIVERSITY";
export const SURVEY_FEEDBACK = "SURVEY_FEEDBACK";

export const SET_ORGANIZATION_KEY = "SET_ORGANIZATION_KEY";

export const SET_SURVEY_COMPLETED_ON = "SET_SURVEY_COMPLETED_ON";
export const SET_SURVEY_MODIFIED_ON = "SET_SURVEY_MODIFIED_ON";
export const SET_ENGAGEMENT = "SET_ENGAGEMENT";
export const SET_NEW_SURVEY_SUBMIT = "SET_NEW_SURVEY_SUBMIT";
export const ON_SIDEBAR = "ON_SIDEBAR";
