// Reference 1: https://dev.to/rossanodan/building-a-navigation-drawer-with-material-ui-and-react-router-dom-1j6l
// Reference 2: https://codesandbox.io/s/material-demo-605w9?file=/demo.js:3944-3951

import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Drawer,
  Grid,
  Typography,
  Hidden,
  Link as MuiLink,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import * as types from "../redux/types";
import { onSideBarAction, selectSurvey, setEngagement } from "../redux/actions";
import spLogo from "../assets/img/landing-logo.png";
import IconOne from "../assets/img/m-icon-1.svg"
import IconTwo from "../assets/img/m-icon-2.svg"
import IconThree from "../assets/img/m-icon-3.svg"
import IconFour from "../assets/img/m-icon-4.svg"
import { Tx, tx } from "../translate";
import "../assets/scss/App.scss";

const drawerWidth = 310;
const CustomListItem = withStyles((theme) => ({
  fontSize: "15px",
  selected: {
    backgroundColor: "#336472 !important",
    color: theme.palette.white.main,
    fontWeight: 700,
  },
}))(ListItem);

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.info.contrastText,
    borderRight: "none",
    zIndex: 1,
    padding: 16,
  },
  link: {
    textAlign: "center",
  },
  toolbar: { ...theme.mixins.toolbar, backgroundColor: "white" },
  logo: {
    height: 65,
    width: "auto",
  },
});

const NavigationBar = ({
  classes,
  open,
  onClose,
  location,
  isLoggedIn,
  appData
}) => {
  const dispatch = useDispatch();
  const isFeedbackSurveyAvailable = useSelector(
    (state) => !!state.appData.surveys?.inclusion
  );
  const tenant = useSelector((state) => state.appData.tenant)
  const activeRoute = (routeName) => location.pathname === routeName;

  const drawer = (
    <div className="sideBar">
      <Hidden smDown>
        <Box clone display="flex" justifyContent="center" alignItems="center">
          <div className={classes.toolbar}>
            <img
              src={tenant?.large_icon ? tenant.large_icon : spLogo}
              className={classes.logo}
              alt="Brand logo MyDataProtected by SafePorter"
            />
          </div>
        </Box>
      </Hidden>
      <List component="div"
        className={classes.menuList}
        onClick={() => {
          dispatch(onSideBarAction());
          onClose();
        }}>

        <CustomListItem
          component={NavLink}
          button
          to="/home"
          style={{ textDecoration: "none" }}
          key="/home"
          selected={activeRoute("/home") || activeRoute("/")}
        >
          <ListItemAvatar>
            <Avatar alt="" src={IconOne} />
          </ListItemAvatar>
          <ListItemText
            className={classes.link}
            primaryTypographyProps={{ variant: "h5" }}
            primary={tx("home")}
          />
        </CustomListItem>
        {/* {appData?.surveys?.engagement1 && (
          <CustomDropdownListItem
            to="/engagement-survey"
            primaryText={tx("engagement_survey")}
            icon={IconTwo}
            submenuItems={[
              {
                to: "/engagement-survey",
                primaryText: "Job Satisfaction and Work Environment",
              },
              {
                to: "/engagement-survey",
                primaryText: "Professional Growth and Opportunities",
              },
              {
                to: "/engagement-survey",
                primaryText: "Work-Life Balance and Flexibility",
              },
              {
                to: "/engagement-survey",
                primaryText: "Organizational Culture and Values",
              },
              {
                to: "/engagement-survey",
                primaryText: "Professional Growth and Opportunities",
              },
              {
                to: "/engagement-survey",
                primaryText: "Leadership and Organizational Management",
              },
            ]}
            activeRoute={activeRoute}
            onClose={onClose}
            isDiversityDisabled={appData?.surveys?.diversity?.last_response_time ? false : isDiversityDisabled}
          />
        )} */}
        {/* {appData?.surveys && Object.keys(appData?.surveys).findIndex((key)=> key.search(/engagement/) > - 1 ) && (
          <CustomDropdownListItem
            to="/engagement-survey"
            primaryText={tx("engagement_survey")}
            icon={IconTwo}
            activeRoute={activeRoute}
            onClose={onClose}
            isDiversityDisabled={
              appData?.surveys?.diversity?.last_response_time
                ? false
                : isDiversityDisabled
            }
          />
        )} */}
        {appData?.surveys && Object.keys(appData?.surveys).sort().find(
          (key) =>
            key.search(/engagement/) > -1) && (

            <CustomListItem
              component={NavLink}
              button
              to="/engagement-survey"
              style={{ textDecoration: "none" }}
              key="/engagement-survey"
              selected={activeRoute("/engagement-survey")}
              onClick={() => dispatch(setEngagement(null))}
            >
              <ListItemAvatar>
                <Avatar alt="" src={IconTwo} />
              </ListItemAvatar>
              <ListItemText
                className={classes.link}
                primaryTypographyProps={{ variant: "h5" }}
                primary={tx("engagement_survey")}
              />
            </CustomListItem>
          )}
        {isFeedbackSurveyAvailable && (
          <>
            {/* <CustomListItem
                    component={Link}
                    button
                    disabled
                    to="/other-surveys"
                    style={{ textDecoration: "none" }}
                    key="/other-surveys"
                    selected={activeRoute("/other-surveys")}
                >
                    <ListItemText
                        className={classes.link}
                        primaryTypographyProps={{ variant: "h5" }}
                        primary={tx('other_surveys')}
                    />
                </CustomListItem> */}
            {/* <CustomListItem
                        component={Link}
                        button
                        disabled
                        to="/all-surveys"
                        style={{ textDecoration: "none" }}
                        key="/all-surveys"
                        selected={activeRoute("/all-surveys")}
                    >
                        <ListItemText
                            className={classes.link}
                            primary={tx('all_surveys')}
                            primaryTypographyProps={{ variant: "h5" }}
                        />
                    </CustomListItem> */}
            <CustomListItem
              component={Link}
              button
              disabled={!isFeedbackSurveyAvailable}
              to="/feedback-survey"
              style={{ textDecoration: "none" }}
              key="/feedback-survey"
              selected={activeRoute("/feedback-survey")}
              // disabled={isFeedbackDisabled}
              // disabled={appData?.surveys?.diversity?.last_response_time ? false : isDiversityDisabled}
              onClick={() => {
                dispatch(selectSurvey(types.SURVEY_FEEDBACK));
                onClose();
              }}
            >
              <ListItemAvatar>
                <Avatar alt="" src={IconThree} />
              </ListItemAvatar>
              <ListItemText
                className={classes.link}
                primary={tx("inclusion_feedback")}
                // secondary={tx('feedback_portal_disc')}
                primaryTypographyProps={{ variant: "h5" }}
                secondaryTypographyProps={{ color: "textPrimary" }}
              />
            </CustomListItem>
          </>
        )}
        {appData?.surveys?.diversity && (
          <CustomListItem
            component={NavLink}
            button
            to="/diversity-survey"
            style={{ textDecoration: "none" }}
            key="/diversity-survey"
            selected={activeRoute("/diversity-survey") || activeRoute("/")}
            onClick={() => {
              dispatch(selectSurvey(types.SURVEY_DIVERSITY));
              onClose();
            }}
          // disabled={isDiversityDisabled}
          >
            <ListItemAvatar>
              <Avatar alt="" src={IconFour} />
            </ListItemAvatar>
            <ListItemText
              className={classes.link}
              primaryTypographyProps={{ variant: "h5" }}
              primary={tx("d_and_i_survey")}
            />
          </CustomListItem>
        )}
      </List>
      <Grid
        item
        xs={12}
        md={10}
        style={{ position: "absolute", bottom: 5, width: "100%" }}
      >
        <Typography align="center" gutterBottom>
          <MuiLink
            href="https://www.safeportersecure.com/privacynotice"
            color="inherit"
            underline="always"
            target="_blank"
          >
            <Tx>lbl_privacy_policy</Tx>
          </MuiLink>
        </Typography>
      </Grid>
    </div>
  );

  return isLoggedIn ? (
    <nav className={classes.drawer} aria-label="primary navigation">
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  ) : null;
};

NavigationBar.propTypes = {
  location: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles)(NavigationBar));
