import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import React, { useContext } from "react"
import AppContext from "./AppContext"
import { Tx, tx } from "../translate"

import Logo from "../assets/img/MyDataProtected.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  logo: {
    maxWidth: 64,
  },
  title: {
    textAlign: "center",
    padding: "50px 50px 25px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },
  },
  content: {
    padding: "16px 24px",
  },
  buttonContainer: {
    paddingTop: 20,
    borderTop: "1px solid #dee2e6",
  },
  stepButton: {
    color: "#70d9b8",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  labelRoot: {
    marginTop: 10,
  },
}));

function ActionCheckBoxes({ step, consent, onSubmit }) {
  const classes = useStyles();
  console.log({ consent })
  return (
    <DialogActions>
      <Grid
        container
        className={classes.buttonContainer}
        justify="flex-end"
        spacing={2}
      >
        <Grid item>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            className={classes.sendCodeButton}
            disabled={consent == null}
          >
            {tx("submit")}
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  );
}



const getTrustMetrics = (answers, trustMetrics, language) => {
  var tmd = []
  try {
    // convert all metrics to lookup:  
    var trustMetricNames = {}
    trustMetrics.map(metric => {
      trustMetricNames[metric.key] = {};
      metric.options.map(option => {
        const answer = "name_localized" in option ? language in option.name_localized ? option.name_localized[language] : option.name_localized['en-us'] : option.name
        const category = "category_localized" in metric ? language in metric.category_localized ? metric.category_localized[language] : metric.category_localized['en-us'] : metric.category
        trustMetricNames[metric.key][option.key] = { "category": category, "answer": answer }
      })
    })
    answers.match(/.{1,2}/g).map(metric => {
      tmd.push({ category: trustMetricNames[metric.charAt(0)][metric.charAt(1)].category, answer: trustMetricNames[metric.charAt(0)][metric.charAt(1)].answer })
    })
  } catch (ex) {
    tmd = [{ category: "--", answer: "--" }]
  }
  return (tmd)
}
// 4xQqsAAoLRn4hcspn8FUjk.AjTn
export default function AcceptTrustMetricDialog({
  answers,
  setShowSurvey,
  setTrustMetrics
}) {
  const userSettings = useContext(AppContext)
  const trustMetrics = useSelector((state) => state.appData.trustMetricsValues)
  const tenant = useSelector((state) => state.appData.tenant)
  const [consent, setConsent] = React.useState(null);
  const [close, setClose] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const trust_metrics = getTrustMetrics(answers, trustMetrics, userSettings.language)

  const onClick = React.useCallback((e) => {
    setConsent(e.target.value);
  }, []);

  const onSubmit = React.useCallback(() => {
    userSettings.setTrustMetricsAccepted(consent === "true" ? "verified" : "not_verified")
    setTrustMetrics(consent === "true" ? "verified" : "not_verified");
    setShowSurvey(true);
    setClose(true);
  }, [consent, step, setTrustMetrics]);

  const classes = useStyles();
  return (
    <Dialog open={open && !close} fullWidth>
      <DialogTitle className={classes.title} disableTypography>
        <img src={tenant?.large_icon ? tenant.large_icon : Logo} className={classes.logo} />
        <Typography variant="h5" gutterBottom>
          <strong><Tx>tmd_welcome</Tx> MyDataProtected! </strong>
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.root} spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {step === 2
                ? <><Tx>tmd_no_access</Tx></>
                : <><Tx>tmd_your</Tx> SafePorter ID (SPID) <Tx>tmd_has_been</Tx></>}
            </Typography>
          </Grid>
          {step === 1 && (
            <>

              <Grid item xs={12}>
                {trust_metrics.map((metric) =>
                  <Typography variant="h6" gutterBottom key={metric.category}>
                    {metric.category !== 'Location' ?
                      <strong> {metric.category} : {metric.answer} </strong> : ''}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
        {step === 1 && (
          <>
            <RadioGroup value={consent} onChange={onClick}>
              <FormControlLabel
                value='true'
                control={<Radio color="primary" />}
                label={<Tx>tmd_i_consent</Tx>}
                classes={{ root: classes.labelRoot }}
              />
              <FormControlLabel
                value='false'
                control={<Radio color="primary" />}
                label={<Tx>tmd_i_dont_consent</Tx>}
                classes={{ root: classes.labelRoot }}
              />
            </RadioGroup>
          </>
        )}
        {/* Remove 2nd pop-up  */}
        {/* {step === 2 && (
          <>
            <RadioGroup value={consent} onChange={onClick}>
              <FormControlLabel
                value='false'
                control={<Radio color="primary" />}
                label={<Tx>tmd_still_no_consent</Tx>}
                classes={{ root: classes.labelRoot }}
              />
              <FormControlLabel
                value='true'
                control={<Radio color="primary" />}
                label={<Tx>tmd_ok_consent</Tx>}
                classes={{ root: classes.labelRoot }}
              />
            </RadioGroup>
          </>
        )} */}
      </DialogContent>

      <ActionCheckBoxes onSubmit={onSubmit} consent={consent} step={step} />
    </Dialog>
  );
}
