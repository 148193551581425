import React from "react"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    withStyles,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Tx, tx } from "../../translate"
import { SPFormFieldset, fieldNameClean } from "../../components/CustomForm"

const styles = (theme) => ({
    root: {
        marginBottom: 4,
    },
    heading: {
        flexBasis: "70%",
        flexShrink: 0,
    },
    fieldContainer: {
        flexGrow: 1,
    },
    accordionHead: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    accordionBody: {
        flexDirection: "column",
    },
    accordionFoot: {
        // backgroundColor: theme.palette.secondary.main,
    },
    buttonPrimary: {
        color: "black",
    }
})

const PerceivedBias = ({
    classes,
    bias,
    control,
    prefix,
    getValues,
    biasCategory,
    setBiasCategory,
    setClose
}) => {
    const [expanded, setExpanded] = React.useState(false)

    const openNext = () => {
        setExpanded((prevProp) => prevProp + 1);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const closeAccordion = () => {
        setClose(false);
    };

    return (
        <Box className="RadioBtnContent">
            <FormControl fullWidth component="fieldset">
                <RadioGroup
                    row
                    name="bias-category"
                    aria-label="radio options for bias category"
                    onChange={(event) => {
                        setBiasCategory(event.target.value)
                    }}
                >
                    <Grid item xs={12} md={6} key="witnessed">
                        <FormControlLabel
                            value="witnessed"
                            control={<Radio color="primary" />}
                            label={tx("whitnessed")}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} key="affected">
                        <FormControlLabel
                            value="affected"
                            control={<Radio color="primary" />}
                            label={tx("affected")}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} key="neither">
                        <FormControlLabel
                            value="neither"
                            control={<Radio color="primary" />}
                            label={tx("neither")}
                        />
                    </Grid>
                </RadioGroup>
            </FormControl>
            {biasCategory === "neither" && (
                <Box align="right">
                    <Button
                        className="OrangeBtn"
                        variant="contained"
                        onClick={closeAccordion}
                        style={{
                            padding: "10px 16px",
                            minWidth: "120px",
                            fontSize: "16px",
                        }}
                    >
                        <b><Tx>next</Tx></b>
                    </Button>
                </Box>
            )}

            {(biasCategory === "witnessed" || biasCategory === "affected") && (
                <Grid container>
                    <Grid item xs={12}>
                        {bias.children.map((category, cIndex) => (
                            <Accordion
                                key={category.title}
                                expanded={expanded === cIndex}
                                onChange={handleChange(cIndex)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-bias-${cIndex}-content`}
                                    id={`panel-bias-${cIndex}-header`}
                                >
                                    <Typography
                                        className={classes.heading}
                                        component="h3"
                                        variant="h6"
                                    >
                                        <b>{category.title}</b>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className="ACFormContent">
                                        <Typography component="span" className="ACLabel" >
                                            {category.select === "one" &&
                                                tx("select_one")}
                                            {category.select === "many" &&
                                                tx("select_all_that_apply")}
                                        </Typography>
                                        <SPFormFieldset
                                            options={category.options}
                                            select={category.select}
                                            title={category.title}
                                            name={fieldNameClean(
                                                `${prefix}${category.title}`
                                            )}
                                            key={`feedback-bias-${category.title}`}
                                            hookFormControl={control}
                                            getValues={getValues}
                                        />
                                    </Box>
                                </AccordionDetails>
                                <AccordionActions>
                                    <Button
                                        className="OrangeBtn"
                                        variant="contained"
                                        onClick={openNext}
                                    >
                                        <b><Tx>next</Tx></b>
                                    </Button>
                                </AccordionActions>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default withStyles(styles)(PerceivedBias)
