import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DocumentTitle from "react-document-title";
import withAuth from "../../components/withAuth";
import { Tx, tx } from "../../translate";
import { useHistory } from "react-router-dom";
import EngagementSurveyPortal from "../../components/Engagement";
import InclusionFeedbackPortal from "../../components/InclusionFeedback";
import DiversityIdentitySurvey from "../../components/Diversity";
import { selectSurvey } from "../../redux/actions";
import * as types from "../../redux/types";
import AcceptTrustMetricDialog from "../../components/AcceptTrustMetricDialog"

const styles = (theme) => ({
  heading: {
    flexShrink: 0,
  },
  accordionHead: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  accordionMain: {
    border: `4px solid ${theme.palette.info.main}`,
  },
  accordionBody: {
    flexDirection: "column",
  },
  formTitle: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  svg: {
    marginRight: 8,
    verticalAlign: "text-top",
  },
});

const Home = ({ classes, setIsDiversityDisabled, isDiversityLastResponse }) => {
  const [scrolledToTop, setScrolledToTop] = React.useState(false);
  const { errors } = useForm();
  const errorDetails = Object.entries(errors);
  const history = useHistory();
  const diversity = useSelector((state) => state.appData.diversitySurvey);
  const onSideBar = useSelector((state) => state.appData.onSideBar);
  const inclusion = useSelector(
    (state) => state.appData?.feedbackSurvey
  );
  const engagementSurveys = useSelector((state) => state.appData.surveys);
  const engagementKey = Object.keys(engagementSurveys).sort().find(
    (key) =>
      key.search(/engagement/) > -1);
  const dispatch = useDispatch()
  const engagement = engagementSurveys[engagementKey]
  const scrollToElement = (selector) => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const surveySubmitted = useSelector((state) => state.appData.surveySubmitted);

  // Needed for the trust metrics acknowledgement.
  const trustMetricMethod = useSelector((state) => state.appData.trustMetricMethod)
  const trustMetricsFlag = useSelector((state) => state.appData.trustMetrics);
  const trustMetricData = useSelector((state) => state.appData.trustMetricData);
  const haveAnswers = trustMetricData ?? false;
  const [trustMetrics, setTrustMetrics] = React.useState("");
  const [showSurvey, setShowSurvey] = React.useState(false);

  // Scroll error details into view
  React.useEffect(() => {
    if (errorDetails.length > 0 && !scrolledToTop) {
      scrollToElement("header");
      setScrolledToTop(true);
    }
    // Update surveySubmitted  
    if (surveySubmitted) {
      dispatch(selectSurvey(types.SURVEY_FEEDBACK));
    }
  }, [errorDetails, scrolledToTop, surveySubmitted]);

  // Navigate to page if one of the condition match
  React.useEffect(() => {
    const engagementSur = engagementSurveys[Object.keys(engagementSurveys).sort().find(
      (key) =>
        key.search(/engagement/) > -1 && !engagementSurveys[key]['last_response_time'])];
    if (!diversity?.last_response_time && !onSideBar) {
      history.push("/home");
    } /* else if (!inclusion?.last_response_time && !onSideBar) {
      history.push("/feedback-survey");
    } */ else if (engagementSur && !onSideBar) {
      history.push("/engagement-survey");
    }
  }, [diversity, inclusion, engagement]);

  // Scroll to top on Page load
  React.useEffect(() => {
    scrollToElement("header");
  }, []);

  // Use the history object to redirect to the desired page
  const redirectToOtherPage = (page) => {
    history.push(page);
  };

  console.log(trustMetricData)
  console.log(trustMetricMethod)
  console.log(trustMetricsFlag)
  console.log(haveAnswers)
  console.log(showSurvey)

  // Display trust metric popup only if user has not accepted the consent and answers are available
  if (trustMetricMethod === 'Accept' && trustMetricsFlag !== 'verified' && haveAnswers && !showSurvey) {
    return <AcceptTrustMetricDialog setTrustMetrics={setTrustMetrics} setShowSurvey={setShowSurvey} answers={trustMetricData} setOpen='true' />
  }

  return (
    <div className="DashboardSheet TextAlignCenter">
      <Grid container spacing={2.2}>
        <Grid item className="HeadingText DashboardMiddleLogo" md={12}>
          <Typography variant="h5" gutterBottom>
            <Tx>tmd_welcome</Tx> MyData<span>Protected!</span>
          </Typography>
          <Typography>
            <Tx>welcome_landing_heading_1</Tx> <br />
          </Typography>
        </Grid>

        {engagement && (
          <EngagementSurveyPortal redirectToOtherPage={redirectToOtherPage} />
        )}

        {inclusion && (
          <InclusionFeedbackPortal redirectToOtherPage={redirectToOtherPage} />
        )}

        {diversity && (
          <DiversityIdentitySurvey redirectToOtherPage={redirectToOtherPage} />
        )}
      </Grid>
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SubmitErrorBox = ({ areFieldsRequired }) => {
  return (
    <DocumentTitle title={tx("error_submit_form")}>
      <Box
        mt={1}
        mb={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        role="alert"
      >
        <Typography color="error" variant="h6">
          {!areFieldsRequired ? tx("please_one") : tx("please_all")}
        </Typography>
      </Box>
    </DocumentTitle>
  );
};

export default withAuth(withStyles(styles)(Home));
