import React from "react"
import PropTypes from "prop-types"
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Tooltip,
    makeStyles,
    withStyles,
} from "@material-ui/core"
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const styles = {
    tooltipSpan: {
        display: "flex",
    },
    tooltip: {
        color: "lightblue",
        backgroundColor: "green",
    },
    mobileSliderBox: {
        minHeight: "130px",
    },
}

const useStyles = makeStyles({
    allyLabel: {
        fontStyle: "italic",
    },
    typography: {
        marginTop: 10,
    },
})

const BlackTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "black",
        fontSize: "1em",
    },
    arrow: {
        color: "black",
    },
})(Tooltip)

const HelpTooltip = withStyles(styles)(({ desc, classes }) => (
    <span className={classes.tooltipSpan}>
        <BlackTooltip title={desc} arrow enterTouchDelay={0}>
            <HelpOutlineOutlinedIcon sx={{ color: '#8a8a8a' }} />
        </BlackTooltip>
    </span>
))

const AllyComponent = ({
    title,
    name,
    isNoAnswerSelected,
    handleCheck,
    addRemoveCheckboxValues,
    isChecked,
    nameOfChecked,
    option,
    props
}) => {
    const classes = useStyles()

    return (
        <Grid className="AllComponentGrid" item xs={12} md={6} key={`${title}_Ally`}>
            <Box display="flex" alignItems="center">
                <FormControlLabel
                    value={option.key}
                    control={
                        <Checkbox
                            checked={isChecked("Ally")}
                            disabled={
                                nameOfChecked.length === 0 || isNoAnswerSelected
                            }
                            color="primary"
                            name="Ally"
                            onChange={(e) => {
                                props.onChange(handleCheck(option.key, name))
                                addRemoveCheckboxValues(
                                    e.target.checked,
                                    e.target.name
                                )
                            }}
                        />
                    }
                    label={option.title}
                    className={classes.allyLabel}
                />
                {option?.description?.length > 0 && (
                    <HelpOutlineOutlinedIcon sx={{ color: '#8a8a8a' }} desc={option?.description} />
                )}
            </Box>
        </Grid>
    )
}

export { AllyComponent }
