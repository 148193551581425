import React, { useContext } from "react"
import PropTypes from "prop-types"
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    makeStyles,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import * as types from "../redux/types"
import { setNewSurveySubmit } from "../redux/actions"
import { useHistory } from "react-router-dom";
import DocumentTitle from "react-document-title"
import { Link } from "react-router-dom"
import { Tx, tx } from "../translate"
const useStyles = makeStyles({
    button: {
        textAlign: "center",
        margin: "20px 0px",
    },
})

const ThankYou = ({ selectedSurvey }) => {
    const survey = useSelector((State) => State.appData.diversitySurvey)
    const error = useSelector((State) => State.appData.errorMessage)
    const section = React.useRef(null)
    const dispatch = useDispatch()
    const history = useHistory();
    const inclusion = useSelector(
        (state) => state.appData?.feedbackSurvey
    );
    const engagementSurveys = useSelector((state) => state.appData.surveys);
    const engagementKey = Object.keys(engagementSurveys).sort().find(
        (key) =>
            key.search(/engagement/) > -1 && !engagementSurveys[key]['last_response_time']);
    const engagement = engagementSurveys[engagementKey]

    const onClick = React.useCallback(() => {
        dispatch(setNewSurveySubmit())
        if (survey && !survey?.last_response_time) {
            history.push("/diversity-survey");
        } /* else if (inclusion && !inclusion?.last_response_time) {
            history.push("/feedback-survey");
        } */ else if (engagement) {
            history.push("/engagement-survey");
        } else {
            history.push("/home")
        }
    }, [])

    // Accessibility: focus on form on initial load
    React.useEffect(() => {
        section.current.focus()
    }, [])

    const classes = useStyles()
    if (error) {
        return (
            <DocumentTitle title="Error accepting response">
                <Paper>
                    <section aria-label="Error details" ref={section}>
                        <Typography
                            variant="h3"
                            component="h1"
                            align="center"
                            color="error"
                            gutterBottom
                        >
                            <Tx>not_at_this_time</Tx>
                        </Typography>
                        <Typography
                            component="p"
                            align="center"
                            color="textPrimary"
                            gutterBottom
                        >
                            <Tx>a_problem</Tx>
                        </Typography>
                        <Typography
                            variant="h4"
                            component="h2"
                            align="center"
                            color="primary"
                            gutterBottom
                        >
                            <Tx>your_id</Tx> <b>{survey.sp_id}</b>
                        </Typography>
                    </section>
                </Paper>
            </DocumentTitle>
        )
    }

    return selectedSurvey === "SURVEY_DIVERSITY" ? (
        <Grid container>
            <Grid item xs={12}>
                <DocumentTitle title={tx("thank_you")}>
                    <Box clone p={4}>
                        <Paper>
                            <section
                                aria-label="Submission thank you message"
                                ref={section}
                            >
                                <Typography
                                    variant="h3"
                                    component="h1"
                                    align="center"
                                    color="primary"
                                    gutterBottom
                                >
                                    <Tx>thank_you</Tx>
                                </Typography>

                                <Box pt={4}>
                                    <Typography
                                        component="p"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>if_you_wish_update</Tx>
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        color="textPrimary"
                                        gutterBottom
                                    >

                                        {/* <Tx>your_id</Tx> <b>{survey.sp_id}</b> */}
                                    </Typography>
                                </Box>

                                <Box pt={4}>
                                    <Typography
                                        component="p"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>you_can_overwrite</Tx>
                                    </Typography>
                                    <Typography
                                        component="p"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>ty_1</Tx>
                                        <br />
                                        <Tx>ty_2</Tx>
                                        <br />
                                        <Tx>ty_3</Tx>
                                    </Typography>
                                    <Typography
                                        component="p"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>any_prior</Tx>
                                    </Typography>
                                </Box>

                                <Box pt={4}>
                                    <Typography
                                        component="p"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>to_delete</Tx>
                                    </Typography>
                                </Box>

                                <Box pt={4}>
                                    <Typography
                                        component="p"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>we_have_no_access</Tx>
                                    </Typography>
                                </Box>
                            </section>
                        </Paper>
                    </Box>
                </DocumentTitle>
            </Grid>

            <Grid item xs={12} className={classes.button}>
                {/* <Link to="/" style={{ textDecoration: "none" }}> */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    style={{ textTransform: "initial" }}
                >
                    <Tx>thank_you</Tx>
                </Button>
                {/* </Link> */}
            </Grid>
        </Grid>
    ) : (
        <Grid container>
            <Grid item xs={12}>
                <DocumentTitle title="Thank you for submitting organization feedback">
                    <Box clone p={4}>
                        <Paper>
                            <section
                                aria-label="Submission thank you message"
                                ref={section}
                            >
                                <Typography
                                    variant="h3"
                                    component="h1"
                                    align="center"
                                    color="primary"
                                    gutterBottom
                                >
                                    <Tx>ty_for_feedback</Tx>
                                </Typography>

                                <Box pt={4}>
                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        align="center"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        <Tx>your_id</Tx> <b>{survey.sp_id}</b>
                                    </Typography>
                                </Box>
                            </section>
                        </Paper>
                    </Box>
                </DocumentTitle>
            </Grid>
            <Grid item xs={12} className={classes.button}>
                {/* <Link to="/" style={{ textDecoration: "none" }}> */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    style={{ textTransform: "initial" }}
                >
                    <Tx>thank_you</Tx>
                </Button>
                {/* </Link> */}
            </Grid>{" "}
        </Grid>
    )
}

ThankYou.propTypes = {
    selectedSurvey: PropTypes.string.isRequired,
}

export default ThankYou
