import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import IconChart from "../assets/img/chart-icon.svg";
import { Tx } from "../translate";
import { useSelector } from "react-redux";

const InclusionFeedbackPortal = ({
  redirectToOtherPage,
}) => {
  const [isDiversityDisabled, setIsDiversityDisabled] = React.useState(false);
  const diversitySurveyTime = useSelector(
    (state) => state?.appData?.diversitySurvey
  );
  React.useEffect(() => {
    if (diversitySurveyTime && diversitySurveyTime?.last_response_time === undefined) {
      setIsDiversityDisabled(true);
    }
  }, [diversitySurveyTime, isDiversityDisabled]);

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} className="ESP">
              <Box display="flex" sx={{ mb: 4.6, justifyContent: "Center" }}>
                <img src={IconChart} alt="" />
              </Box>
              <Typography variant="h5">
                {" "}
                <Tx>inclusion_feedback_portal</Tx>{" "}
              </Typography>
              <Typography className="TextAlignStart">
                <Tx>inclusion_feedback_portal_card_heading</Tx>{" "}
              </Typography>
              <Button
                variant="contained"
                // disabled={isDiversityDisabled}
                className="GreenBtn"
                onClick={() => redirectToOtherPage("/feedback-survey")}
              >
                <Tx>inclusion_feedback_button</Tx>{" "}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default InclusionFeedbackPortal;
