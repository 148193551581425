import React, { useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  AccordionActions,
  withStyles,
  Radio,
  RadioGroup,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import { Tx, tx } from "../../translate";
import AcceptTrustMetricDialog from "../../components/AcceptTrustMetricDialog";
import {
  SPFormSliderFieldset,
  fieldNameClean,
} from "../../components/CustomForm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useForm } from "react-hook-form";
import withAuth from "../../components/withAuth";
import { useDispatch, useSelector } from "react-redux";
import DocumentTitle from "react-document-title";
import moment from "moment";
import { submitSurvey } from "../../redux/actions";
import ThankYouPage from "../ThankYou";
import Alert from "@material-ui/lab/Alert"
import AppContext from "../../components/AppContext";

const styles = (theme) => ({
  root: {
    marginBottom: 4,
  },
  heading: {
    flexShrink: 0,
  },
  accordionHead: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  accordionBody: {
    border: `4px solid ${theme.palette.secondary.main}`,
  },
  accordionFoot: {
    // backgroundColor: theme.palette.secondary.main,
  },
  buttonPrimary: {
    color: "black",
  },
});

const MOMENT_FORMAT = "MMMM Do, YYYY, h:mm A (z)"

const EngagementSurvey = ({ classes, redirectToOtherPage, engagement }) => {
  const userSettings = useContext(AppContext)
  const trustMetricData = useSelector((state) => state.appData.trustMetricData);
  const haveAnswers = !!trustMetricData;
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState(false);
  const { control, handleSubmit, watch, getValues } = useForm();
  const [allSelectedValues, setAllSelectedValues] = React.useState([]);
  const [value, setValue] = React.useState('');
  const api = useSelector((state) => state.appData.destination);
  const surveySubmitted = useSelector((state) => state.appData.surveySubmitted);
  const submitInProgress = useSelector((state) => state.appData.submitInProgress)
  const whichSurvey = useSelector((State) => State.appData.selectedSurvey);
  const diversity = useSelector((state) => state.appData.diversitySurvey);
  const spId = useSelector((state) => state.appData.spId);
  const tenant_id = useSelector((State) => State.appData.tenant.tenant_id);
  const engagementKey = useSelector((state) => state.appData?.engagementKey);

  const openNext = () => {
    const formValues = getValues();
    engagement.categories = engagement.categories.map(
      (category) => ({
        ...category,
        selectedValue:
          formValues[fieldNameClean(`${"SP-FB-INC_"}${category.title}`)],//used inclusion prefix for test
      })
    );

    const selectedValues = engagement.categories.map(
      (category, cIndex) => {
        // Update the category with selectedValue
        const selectedValue = category.selectedValue;

        // Create an object with the selected value and its index
        return {
          value: selectedValue,
          index: cIndex,
        };
      }
    );
    setAllSelectedValues(selectedValues);
    setExpanded((prevProp) => prevProp + 1);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onChangeOptin = (event) => {
    setValue(event.target.value);
  };

  const onSubmit = React.useCallback((values) => {
    // TODO: cleanup and move to a separate api independent of redux store structure
    const request = { engagement };
    request.engagement.categories = request.engagement.categories.map(
      (category) => ({
        ...category,
        selectedValue:
          values[fieldNameClean(`${"SP-FB-INC_"}${category.title}`)],
      })
    );
    request.engagement.consent_to_include = userSettings.tmdAccepted
    request.engagement.trust_metrics = userSettings.tmdAccepted
    dispatch(submitSurvey(request.engagement, api, {
      spId,
      tenant_id,
      language: userSettings.language
    }, engagementKey))
  }, [value]);

  // replace class after submit an answer
  React.useEffect(() => {
    allSelectedValues.map((data) => {
      if (data?.value !== 0) {
        var element = document.getElementById(
          `panel-surveyDiversity-${data.index}-accordion`
        );
        element.classList.add("FilledAccordion");
      }
    });
    // Display trust metric popup only if user has not accepted the consent and answers are available

  }, [allSelectedValues]);

  // to do after submit engagement data 
  if (surveySubmitted) {
    return <ThankYouPage selectedSurvey={whichSurvey} />;
  }

  return (
    <DocumentTitle title="Feedback Portal">
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={10} xl={11}>
          {/* Static data for now  */}
          <Box className="pageHeading">
            <Typography><Tx>engagement_survey</Tx></Typography>
            <Typography variant="h2" component="h2">
              {engagement.title}
            </Typography>
            <Typography>
              {engagement.last_response_time ? <>
                <Tx>completed_on</Tx>: {moment(engagement.last_response_time)
                  .tz(moment.tz.guess(true))
                  .format(MOMENT_FORMAT)}
              </> : <>
                {/* <Tx>assigned_on</Tx> sdfasdf: {moment(engagement.issue_date)
                  .tz(moment.tz.guess(true))
                  .format(MOMENT_FORMAT)} */}
              </>}
            </Typography>
          </Box>
          <Grid container>
            {engagement?.categories?.length > 0 ? <Grid item xs={12}>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Box className="NumberAccordion">
                  {engagement?.categories?.map((category, cIndex) => (
                    <Accordion
                      key={category.title}
                      expanded={expanded === cIndex}
                      onChange={handleChange(cIndex)}
                      id={`panel-surveyDiversity-${cIndex}-accordion`}
                      className="ThemeAccordion"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-inclusion-${cIndex}-content`}
                        id={`panel-inclusion-${cIndex}-header`}
                      >
                        <Typography
                          id={`panel-inclusion-${cIndex}-title`}
                          component="h2"
                          variant="h6"
                        >
                          {category.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionBody}>
                        <Grid container justify="center">
                          <Grid item xs={10}>
                            <SPFormSliderFieldset
                              ariaLabelledBy={`panel-inclusion-${cIndex}-title`}
                              control={control}
                              name={fieldNameClean(
                                `${"SP-FB-INC_"}${category.title}`
                              )}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                      <AccordionActions>
                        <Button
                          className="OrangeBtn"
                          variant="contained"
                          onClick={openNext}
                        >
                          <b>
                            <Tx>next</Tx>
                          </b>
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  ))}
                </Box>
                {diversity && diversity?.last_response_time && (
                  <Grid container className="TermsText">
                    <Grid item xs={12} className={classes.alert}>
                      <Alert severity="info" sx={{ bgcolor: "#fff000" }}>
                        <Tx>org_no_access</Tx>
                      </Alert>
                    </Grid>
                    <RadioGroup
                      aria-label={`radio options for optInSurveyInfo`}
                      name="optInSurveyInfo"
                      value={value}
                      onChange={onChangeOptin}
                    >
                      <FormControlLabel
                        value="i_opt_in"
                        control={<Radio color="primary" />}
                        label={tx("i_opt_in")}
                      />
                      <FormControlLabel
                        value="not_opt_in"
                        control={<Radio color="primary" />}
                        label={tx("not_opt_in")}
                      />
                    </RadioGroup>
                  </Grid>
                )}
                {submitInProgress && (
                  <Box mb={2} mt={2} display="flex" justifyContent="center">
                    <CircularProgress color="secondary" id="sp-submit-spinner" />
                  </Box>
                )}
                <Box
                  mt={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={(diversity && diversity?.last_response_time && !value) || submitInProgress}
                  >
                    <b>{tx("submit")}</b>
                  </Button>
                </Box>
              </form>
            </Grid> : <b>{tx('engagement_no_data')}</b>}

          </Grid>
        </Grid>
      </Grid>
    </DocumentTitle>
  );
};

export default withAuth(withStyles(styles)(EngagementSurvey));
