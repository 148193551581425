import React from "react"
import PropTypes from "prop-types"
import {
    Box,
    FormControl,
    Slider,
    TextField,
    withStyles,
    withWidth,
    isWidthUp,
} from "@material-ui/core"
import { Controller } from "react-hook-form"
import { without } from "lodash"
import { tx } from "../translate"
import { SelectOneComponent } from "./SelectOneComponent"
import { SelectManyComponent } from "./SelectManyComponent"
import { SearchComponent } from "./SearchComponent"
import { MultiRacialComponent } from "./MultiRacialComponent"
import { GenderComponent } from "./GenderComponent"

const styles = {
    mobileSliderBox: {
        minHeight: "130px",
    },
}

const SPFormFieldset = ({
    options = [],
    select,
    title,
    name,
    hookFormControl,
    getValues = () => {},
    isRequired,
}) => {
    const dedupe = []
    const rawset = []
    const [isNoAnswerSelected, setIsNoAnswerSelected] = React.useState(false)
    const [nameOfChecked, setNameOfChecked] = React.useState([])

    options.forEach((option) => {
        if (!dedupe.includes(option.title)) {
            dedupe.push(option.title)
            rawset.push(option)
        }
    })

    const isManyOptions = rawset.length > 25

    const isOnlyAllySelected = React.useMemo(() => {
        if (
            nameOfChecked.length === 1 &&
            nameOfChecked.indexOf("Ally") !== -1
        ) {
            return true
        }
        return false
    }, [nameOfChecked])

    const handleCheck = React.useCallback(
        (checkedId, fieldName, override = false) => {
            const values = getValues()
            const selectedValues = values[fieldName]
            const valueExists = selectedValues?.includes(checkedId)
            if (!valueExists) {
                if (override) {
                    return [checkedId]
                }
                return [...(selectedValues ?? []), checkedId]
            }
            const indexOfAlly = selectedValues.indexOf("Ally")
            if (indexOfAlly !== -1 && selectedValues.length === 2) {
                selectedValues.splice(0, selectedValues.length)
            }
            return selectedValues?.filter((id) => id !== checkedId)
        },
        []
    )

    const addRemoveCheckboxValues = React.useCallback(
        (checkEvent, nameEvent) => {
            if (checkEvent) {
                setNameOfChecked([...nameOfChecked, nameEvent])
            } else {
                setNameOfChecked(without(nameOfChecked, nameEvent))
            }
        },
        [nameOfChecked]
    )

    const isChecked = React.useCallback(
        (title) => {
            if (isNoAnswerSelected || isOnlyAllySelected) {
                return false
            } else {
                return nameOfChecked && nameOfChecked.indexOf(title) !== -1
            }
        },
        [isNoAnswerSelected, nameOfChecked]
    )

    React.useEffect(() => {
        if (isNoAnswerSelected || isOnlyAllySelected) {
            setNameOfChecked([])
        }
    }, [isNoAnswerSelected, isOnlyAllySelected])

    if (!isManyOptions) {
        if (rawset.some((option) => option.non_binary)) {
            return (
                <GenderComponent
                    hookFormControl={hookFormControl}
                    title={title}
                    name={name}
                    isRequired={isRequired}
                    rawset={rawset}
                />
            )
        }

        if (rawset.some((option) => option.multi_racial)) {
            return (
                <MultiRacialComponent
                    hookFormControl={hookFormControl}
                    title={title}
                    name={name}
                    isRequired={isRequired}
                    rawset={rawset}
                />
            )
        }
    }

    return (
        <FormControl fullWidth component="fieldset">
            {select === "one" && (
                <SelectOneComponent
                    title={title}
                    name={name}
                    hookFormControl={hookFormControl}
                    isRequired={isRequired}
                    rawset={rawset}
                />
            )}
            {select === "many" && (
                <SelectManyComponent
                    title={title}
                    name={name}
                    hookFormControl={hookFormControl}
                    isRequired={isRequired}
                    rawset={rawset}
                    setIsNoAnswerSelected={setIsNoAnswerSelected}
                    isNoAnswerSelected={isNoAnswerSelected}
                    handleCheck={handleCheck}
                    addRemoveCheckboxValues={addRemoveCheckboxValues}
                    isChecked={isChecked}
                    nameOfChecked={nameOfChecked}
                />
            )}
            {select === "search" && (
                <SearchComponent
                    control={hookFormControl}
                    id={`${title}_mutiple_select`}
                    name={name}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Type to search..."
                        />
                    )}
                    defaultValue={[]}
                    isRequired={isRequired}
                    rawset={rawset}
                    isMultiple
                />
            )}
        </FormControl>
    )
}

SPFormFieldset.propTypes = {
    options: PropTypes.array.isRequired,
    select: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hookFormControl: PropTypes.object.isRequired,
    isRequired: PropTypes.bool.isRequired,
}

const SPFormSliderFieldset = withWidth()(
    withStyles(styles)(({ width, classes, name, ariaLabelledBy, control }) => {
        // TODO: remove hardcoded marks
        const marks = [
            {
                value: -10,
                label: tx("strongly_disagree"),
            },
            {
                value: -5,
                label: tx("disagree"),
            },
            {
                value: 0,
                label: tx("neutral"),
            },
            {
                value: 5,
                label: tx("agree"),
            },
            {
                value: 10,
                label: tx("strongly_agree"),
            },
        ]

        return (
            <Controller
                name={name}
                control={control}
                defaultValue={0}
                render={({ onChange, value, ...props }) => {
                    if (isWidthUp("md", width)) {
                        return (
                            <Slider
                                track={false}
                                aria-labelledby={ariaLabelledBy}
                                onChange={(_, value) => {
                                    onChange(value)
                                }}
                                max={10}
                                min={-10}
                                step={5}
                                marks={marks}
                                value={value}
                                {...props}
                            />
                        )
                    }
                    return (
                        <Box mt={2} mb={2} display="flex">
                            <Slider
                                className={classes.mobileSliderBox}
                                track={false}
                                aria-labelledby={ariaLabelledBy}
                                onChange={(_, value) => {
                                    onChange(value)
                                }}
                                // component="div"
                                max={10}
                                min={-10}
                                step={5}
                                marks={marks}
                                value={value}
                                orientation="vertical"
                                {...props}
                            />
                        </Box>
                    )
                }}
            />
        )
    })
)

const fieldNameClean = (name) => encodeURI(name).replace(/[^\w\s]/gi, "")

export { SPFormFieldset, SPFormSliderFieldset, fieldNameClean }
