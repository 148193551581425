import React from "react"
import {
    Box,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Tooltip,
    makeStyles,
    withStyles,
} from "@material-ui/core"
import HelpIcon from "@material-ui/icons/Help"
import { Controller } from "react-hook-form"
import { tx } from "../translate"
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const styles = {
    tooltipSpan: {
        display: "flex",
    },
    tooltip: {
        color: "lightblue",
        backgroundColor: "green",
    },
    mobileSliderBox: {
        minHeight: "130px",
    },
}

const BlackTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "black",
        fontSize: "1em",
    },
    arrow: {
        color: "black",
    },
})(Tooltip)

const HelpTooltip = withStyles(styles)(({ desc, classes }) => (
    <span className={classes.tooltipSpan}>
        <BlackTooltip title={desc} arrow enterTouchDelay={0}>
            <HelpOutlineOutlinedIcon sx={{ color: '#8a8a8a' }} />
        </BlackTooltip>
    </span>
))

const SelectOneComponent = ({
    title,
    name,
    hookFormControl,
    isRequired,
    rawset,
}) => {
    return (
        <Controller
            rules={{
                required: isRequired && `Please select a value for ${title}`,
            }}
            control={hookFormControl}
            name={name}
            defaultValue={null}
            as={
                <RadioGroup row aria-label={`radio options for ${title}`}>
                    {rawset.map((option) => (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            key={`${title}_${option.title}`}
                        >
                            <Box display="flex" alignItems="center">
                                <FormControlLabel
                                    value={option.key}
                                    control={<Radio color="primary" />}
                                    label={option.title}
                                />
                                {option?.description?.length > 0 && (
                                    <HelpTooltip desc={option?.description} />
                                )}
                            </Box>
                        </Grid>
                    ))}
                    <Grid item xs={12} md={6} key={`${title}_NoAnswer`}>
                        <FormControlLabel
                            value="NoAnswer"
                            control={<Radio color="primary" />}
                            label={tx("prefer_no_answer")}
                        />
                    </Grid>
                </RadioGroup>
            }
        />
    )
}

export { SelectOneComponent }
