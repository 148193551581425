import React, { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DocumentTitle from "react-document-title"
import { Tx, tx } from "../../translate"
import withAuth from "../../components/withAuth"
import ThankYouPage from "../ThankYou"
import Inclusion from "./Inclusion"
import PerceivedBias from "./PerceivedBias"
import Accessibility from "./Accessibility"
import { submitSurvey } from "../../redux/actions"
import { fieldNameClean } from "../../components/CustomForm"
import AppContext from "../../components/AppContext"

const FORM_PREFIXES = {
  bias: "SP-FB-BIAS_",
  inclusion: "SP-FB-INC_",
  accessibility: "SP-FB-ACCS_",
}

const styles = (theme) => ({
  root: {
    marginBottom: 4,
  },
  accordionHead: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  accordionBody: {
    border: `4px solid ${theme.palette.secondary.main}`,
  },
  buttonPrimary: {
    color: "black",
  },
  formTitle: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  infoContainer: {
    marginTop: 20,
  },
  alert: {
    marginBottom: 10,
  },
})

const Feedback = ({ classes, setIsFeedbackDisabled }) => {
  const userSettings = useContext(AppContext)
  const surveySubmitted = useSelector(
    (state) => state.appData.surveySubmitted
  )
  const whichSurvey = useSelector((State) => State.appData.selectedSurvey)
  const surveyFeedback = useSelector((state) => state.appData.feedbackSurvey)
  const submissionInProgress = useSelector(
    (state) => state.appData.submitInProgress
  )
  const inclusionSurvey = useSelector(
    (state) => state.appData.feedbackSurvey.inclusion
  )
  const biasSurvey = useSelector((state) => state.appData.feedbackSurvey.bias)
  const accessibilitySurvey = useSelector(
    (state) => state.appData.feedbackSurvey.accessibility
  )
  const { control, handleSubmit, watch, getValues } = useForm()
  const formSection = React.useRef(null)
  const [expanded, setExpanded] = React.useState(false)
  const [accessibilityCategory, setAccessibilityCategory] = React.useState("")
  const [allInclusionValues, setAllInclusionValues] = React.useState(false);
  const [allBiasValues, setAllBiasValues] = React.useState(false);
  const [allAccessibility, setAllAccessibility] = React.useState(false);
  const [biasCategory, setBiasCategory] = React.useState("")
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch()
  const api = useSelector((state) => state.appData.destination)
  const diversity = useSelector((state) => state.appData.diversitySurvey);
  const spId = useSelector((state) => state.appData.spId);
  const tenant_id = useSelector((State) => State.appData.tenant.tenant_id)

  // Accessibility: focus on form on initial load
  React.useEffect(() => {
    formSection?.current?.focus()
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    const formValues = getValues();
    const request = { ...surveyFeedback };

    const checkAllValuesNotEmpty = (categories, prefix) =>
      categories.every((category) =>
        formValues[
          fieldNameClean(`${prefix}${category.title}`)
        ]?.length > 0
      );

    const allInclusionValuesNonZero = request.inclusion.categories.every(
      (category) =>
        formValues[
        fieldNameClean(`${FORM_PREFIXES.inclusion}${category.title}`)
        ] !== 0
    );

    const allSelectedValuesNotEmpty = checkAllValuesNotEmpty(
      request.bias.children,
      FORM_PREFIXES.bias
    );

    const allAccessibilityValuesNotEmpty = checkAllValuesNotEmpty(
      request.accessibility.children,
      FORM_PREFIXES.accessibility
    );

    setAllAccessibility(allAccessibilityValuesNotEmpty);
    setAllBiasValues(allSelectedValuesNotEmpty);
    setAllInclusionValues(allInclusionValuesNonZero);
    setExpanded(isExpanded ? panel : false);
  };

  const onChangeOptin = (event) => {
    setValue(event.target.value);
  };

  const onSubmit = React.useCallback(
    (feedback) => {
      // TODO: cleanup and move to a separate api independent of redux store structure
      const request = { ...surveyFeedback }
      // inlcusion
      request.inclusion.categories = request.inclusion.categories.map(
        (category) => ({
          ...category,
          selectedValue:
            feedback[
            fieldNameClean(
              `${FORM_PREFIXES.inclusion}${category.title}`
            )
            ],
        })
      )
      // bias
      request.bias.children = request.bias.children.map((category) => ({
        ...category,
        selectedValue:
          feedback[
          fieldNameClean(`${FORM_PREFIXES.bias}${category.title}`)
          ],
      }))
      // accessibility
      request.accessibility.children = request.accessibility.children.map(
        (category) => ({
          ...category,
          selectedValue:
            feedback[
            fieldNameClean(
              `${FORM_PREFIXES.accessibility}${category.title}`
            )
            ],
        })
      )
      request.consent_to_include = value === 'i_opt_in' ? true : false
      dispatch(submitSurvey(request, api, {
        spId,
        tenant_id,
        language: userSettings.language
      }, 'feedbackSurvey'));
    },
    [submitSurvey, accessibilityCategory, biasCategory, value]
  )

  if (surveySubmitted) {
    setIsFeedbackDisabled(true)
    return <ThankYouPage selectedSurvey={whichSurvey} />
  }

  return (
    <DocumentTitle title="Feedback Portal">
      <Grid container justify="center">
        <Grid item xs={12} md={12} lg={10} xl={10}>
          <Box className="pageHeading">
            <Typography variant="h2" component="h2">
              Inclusion Feedback
            </Typography>
            <Typography>
              Assigned on: March 2nd, 2023, 5:42 PM (PST)
            </Typography>
          </Box>
          <section
            id="feedback-form-section"
            aria-label="Feedback portal form"
            ref={formSection}
          >
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              {/* INCLUSION */}
              <Box className="NumberAccordion">
                <Accordion
                  key="inclusion"
                  expanded={expanded === "inclusion"}
                  onChange={handleChange("inclusion")}
                  className={`ThemeAccordion ${allInclusionValues ? "FilledAccordion" : ""
                    }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-feedback-inclusion-content"
                    id="panel-feedback-inclusion-header"
                  >
                    <Typography component="h2" variant="h6">
                      {inclusionSurvey?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Inclusion
                      inclusion={inclusionSurvey}
                      control={control}
                      prefix={FORM_PREFIXES.inclusion}
                    />
                  </AccordionDetails>
                </Accordion>
                {/* BIAS */}
                <Accordion
                  key="bias"
                  expanded={expanded === "bias"}
                  onChange={handleChange("bias")}
                  className={`ThemeAccordion ${allBiasValues ? "FilledAccordion" : ""
                    }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-feedback-bias-content"
                    id="panel-feedback-bias-header"
                  >
                    <Typography component="h2" variant="h6">
                      {biasSurvey?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionBody}>
                    <PerceivedBias
                      bias={biasSurvey}
                      control={control}
                      getValues={getValues}
                      prefix={FORM_PREFIXES.bias}
                      biasCategory={biasCategory}
                      setBiasCategory={setBiasCategory}
                      setClose={setExpanded}

                    />
                  </AccordionDetails>
                </Accordion>
                {/* ACCESSIBILITY */}
                <Accordion
                  key="accessibility"
                  expanded={expanded === "accessibility"}
                  onChange={handleChange("accessibility")}
                  className={`ThemeAccordion ${allAccessibility ? "FilledAccordion" : ""
                    }`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-feedback-accessibility-content"
                    id="panel-feedback-accessibility-header"
                  >
                    <Typography component="h2" variant="h6">
                      {accessibilitySurvey?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accessibility
                      accessibility={accessibilitySurvey}
                      control={control}
                      watch={watch}
                      getValues={getValues}
                      prefix={FORM_PREFIXES.accessibility}
                      setAccessibilityCategory={setAccessibilityCategory}
                      accessibilityCategory={accessibilityCategory}
                      setClose={setExpanded}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              {diversity && diversity?.last_response_time && (
                <Grid container className="TermsText">
                  <Grid item xs={12} className={classes.alert}>
                    <Alert severity="info" sx={{ bgcolor: "#fff000" }}>
                      <Tx>org_no_access</Tx>
                    </Alert>
                  </Grid>
                  <RadioGroup
                    aria-label={`radio options for optInSurveyInfo`}
                    name="optInSurveyInfo"
                    value={value}
                    onChange={onChangeOptin}
                  >
                    <FormControlLabel
                      value="i_opt_in"
                      control={<Radio color="primary" />}
                      label={tx("i_opt_in")}
                    />
                    <FormControlLabel
                      value="not_opt_in"
                      control={<Radio color="primary" />}
                      label={tx("not_opt_in")}
                    />
                  </RadioGroup>
                </Grid>
              )}
              {/* Form validation errors */}
              {submissionInProgress && (
                <Box mb={2} mt={2} display="flex" justifyContent="center">
                  <CircularProgress color="primary" id="sp-submit-spinner" />
                </Box>
              )}
              <Box
                mt={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  // className="OrangeBtn"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={(!value && diversity && diversity?.last_response_time) || submissionInProgress}
                >
                  <Tx>submit</Tx>
                </Button>
              </Box>
            </form>
          </section>
        </Grid>
      </Grid>
    </DocumentTitle>
  );
}

export default withAuth(withStyles(styles)(Feedback))
