import React from "react"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    withStyles,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Tx, tx } from "../../translate"
import { SPFormFieldset, fieldNameClean } from "../../components/CustomForm"

// TODO: move logic to backend
const FORM_RULES = [
    {
        title: "The physical accessibility issue was related to...",
        dependsOn: {
            title: "The accessibility issue related to...",
            value: "Physical Accessibility",
        },
    },
    {
        title: "The technological accessibility had to do with...",
        dependsOn: {
            title: "The accessibility issue related to...",
            value: "Technological Accessibility",
        },
    },
]

const styles = (theme) => ({
    root: {
        marginBottom: 4,
    },
    heading: {
        flexBasis: "70%",
        flexShrink: 0,
    },
    fieldContainer: {
        flexGrow: 1,
    },
    accordionHead: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    accordionBody: {
        border: `4px solid ${theme.palette.secondary.main}`,
        flexDirection: "column",
    },
    buttonPrimary: {
        color: "black",
    }
})

const Accessibility = ({
    classes,
    accessibility,
    control,
    prefix,
    watch,
    getValues,
    accessibilityCategory,
    setAccessibilityCategory,
    setClose
}) => {
    const [expanded, setExpanded] = React.useState(false)

    const watchLogicFields = watch(
        FORM_RULES.map((field) =>
            fieldNameClean(`${prefix}${field.dependsOn.title}`)
        )
    )

    const displayFieldset = (title) => {
        const rule = FORM_RULES.find((ruleItem) => title === ruleItem.title)

        if (rule === undefined) {
            return true
        }

        const key = fieldNameClean(`${prefix}${rule.dependsOn.title}`)

        return (
            !!watchLogicFields[key] &&
            watchLogicFields[key].includes(rule.dependsOn.value)
        )
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const openNext = () => {
        setExpanded((prevProp) => prevProp + 1);
    }

    const closeAccordion = () => {
        setClose(false);
    };

    return (
        <Box className="RadioBtnContent">
            <FormControl fullWidth component="fieldset">
                <RadioGroup
                    row
                    name="accessibility-category"
                    aria-label="radio options for accessibility category"
                    onChange={(event) => {
                        setAccessibilityCategory(event.target.value)
                    }}
                >
                    <Grid item xs={12} md={6} key="entering">
                        <FormControlLabel
                            value="entering"
                            control={<Radio color="primary" />}
                            label={tx("inc_i_have_witnessed")}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} key="participating">
                        <FormControlLabel
                            value="participating"
                            control={<Radio color="primary" />}
                            label={tx("inc_i_was_affected")}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} key="neither">
                        <FormControlLabel
                            value="neither"
                            control={<Radio color="primary" />}
                            label={tx('inc_neither')}
                        />
                    </Grid>
                </RadioGroup>
            </FormControl>
            {accessibilityCategory === "neither" && (
                <Box align="right">
                    <Button
                        className="OrangeBtn"
                        variant="contained"
                        onClick={closeAccordion}
                        style={{
                            padding: "10px 16px",
                            minWidth: "120px",
                            fontSize: "16px",
                        }}
                    >
                        <b><Tx>next</Tx></b>
                    </Button>
                </Box>
            )}

            {(accessibilityCategory === "entering" ||
                accessibilityCategory === "participating") && (
                    <Grid container>
                        <Grid item xs={12}>
                            {accessibility.children
                                .filter((child) => displayFieldset(child.title))
                                .map((category, cIndex) => (
                                    <Accordion
                                        key={category.title}
                                        expanded={expanded === cIndex}
                                        onChange={handleChange(cIndex)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`accessibility-${cIndex}-content`}
                                            id={`accessibility-${cIndex}-header`}
                                        >
                                            <Typography
                                                className={classes.heading}
                                                component="h3"
                                                variant="h6"
                                            >
                                                <b>{category.title}</b>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            className={classes.accordionBody}
                                        >
                                            <Box className="ACFormContent">
                                                <Typography component="span" className="ACLabel">
                                                    {category.select === "one" &&
                                                        tx("select_one")}
                                                    {category.select === "many" &&
                                                        tx("select_all_that_apply")}
                                                </Typography>
                                                <SPFormFieldset
                                                    options={category.options}
                                                    select={category.select}
                                                    title={category.title}
                                                    name={fieldNameClean(
                                                        `${prefix}${category.title}`
                                                    )}
                                                    key={`feedback-accessibility-${category.title}`}
                                                    hookFormControl={control}
                                                    getValues={getValues}
                                                />
                                            </Box>
                                        </AccordionDetails>
                                        <AccordionActions>
                                            <Button
                                                className="OrangeBtn"
                                                variant="contained"
                                                onClick={openNext}
                                            >
                                                <b><Tx>next</Tx></b>
                                            </Button>
                                        </AccordionActions>
                                    </Accordion>
                                ))}
                        </Grid>
                    </Grid>
                )}
        </Box>
    )
}

export default withStyles(styles)(Accessibility)
