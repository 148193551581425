import React from "react"
import {
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Grid,
    Radio,
    TextField,
    makeStyles,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Controller } from "react-hook-form"
import { tx } from "../translate"
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const styles = {
    tooltipSpan: {
        display: "flex",
    },
    tooltip: {
        color: "lightblue",
        backgroundColor: "green",
    }
}

const useStyles = makeStyles({
    typography: {
        marginTop: 10,
    },
})

const BlackTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "black",
        fontSize: "1em",
    },
    arrow: {
        color: "black",
    },
})(Tooltip)

const HelpTooltip = withStyles(styles)(({ desc, classes }) => (
    <span className={classes.tooltipSpan}>
        <BlackTooltip title={desc} arrow enterTouchDelay={0}>
            <HelpOutlineOutlinedIcon sx={{ color: '#8a8a8a' }} />
        </BlackTooltip>
    </span>
))

const defaultNonBinaryValues = {
    id: "non-binary",
    label: "Non-Binary",
    value: "Non-Binary",
    isNonBinary: true,
}

const GenderComponent = ({
    title,
    name,
    hookFormControl,
    isRequired,
    rawset,
    defaultValue = null,
}) => {
    const options = rawset.map((option) => ({
        id: option.title.toLowerCase().replace(/ /g, "_"),
        value: option.key,
        label: option.title,
        description: option.description,
        isNonBinary: option.non_binary,
        isPrimaryOption: option.primary_option,
        isAlly: option.ally,
    }))

    const nonBinaryKey = rawset.find((option) => option.non_binary).key

    const [nameOfChecked, setNameOfChecked] = React.useState({
        ...defaultNonBinaryValues,
        selectedValues: [nonBinaryKey],
    })
    const [state, setState] = React.useState(null)

    const addRemoveCheckboxValues = React.useCallback(
        (checkEvent, nameEvent) => {
            if (checkEvent) {
                setNameOfChecked(
                    (prev) => prev,
                    nameOfChecked.selectedValues.push(nameEvent)
                )
            } else {
                const indexOfRemoved = nameOfChecked.selectedValues.indexOf(
                    nameEvent
                )
                setNameOfChecked(
                    (prev) => prev,
                    nameOfChecked.selectedValues.splice(indexOfRemoved, 1)
                )
            }
            return nameOfChecked
        },
        [nameOfChecked]
    )

    const classes = useStyles()

    return (
        <Controller
            render={({ onChange, value, ...props }) => (
                <>
                    <Autocomplete
                        id={`${title}_mutiple_select`}
                        options={options.filter(
                            (option) => option.isPrimaryOption
                        )}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) =>
                            option.id === value.id
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Type to search..."
                            />
                        )}
                        onChange={(e, data) => {
                            onChange(data)
                            setState(data)
                            setNameOfChecked({
                                ...defaultNonBinaryValues,
                                selectedValues: [nonBinaryKey],
                            })
                        }}
                        value={state}
                        {...props}
                    />
                    {value?.isNonBinary && (
                        <>
                            <Typography
                                component="h2"
                                variant="h6"
                                align="center"
                                gutterBottom
                                className={classes.typography}
                            >
                                {tx("select_all_that_apply")}
                            </Typography>
                            <Controller
                                control={hookFormControl}
                                name={name}
                                defaultValue={defaultValue}
                                render={(props) => (
                                    <FormGroup row>
                                        {options
                                            .filter(
                                                (option) =>
                                                    !option.isNonBinary &&
                                                    !option.isPrimaryOption
                                            )
                                            .map((option) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    key={`_${option.label}`}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <FormControlLabel
                                                            value={option.value}
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    name={
                                                                        option.value
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        props.onChange(
                                                                            addRemoveCheckboxValues(
                                                                                e
                                                                                    .target
                                                                                    .checked,
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        )
                                                                    }}
                                                                />
                                                            }
                                                            label={option.label}
                                                        />
                                                        {option?.description
                                                            ?.length > 0 && (
                                                            <HelpTooltip
                                                                desc={
                                                                    option?.description
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                </Grid>
                                            ))}
                                    </FormGroup>
                                )}
                            />
                        </>
                    )}
                    {!value?.isNonBinary && (
                        <FormControlLabel
                            key={`${name}_NoAnswer`}
                            value="NoAnswer"
                            control={<Radio color="primary" name="NoAnswer" />}
                            label={tx("prefer_no_answer")}
                            checked={value?.value === "NoAnswer"}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    const noAnswersData = {
                                        id: "noanswer",
                                        label: "No Answer",
                                        value: "NoAnswer",
                                    }

                                    onChange(noAnswersData)
                                    setState(noAnswersData)
                                } else {
                                    onChange(null)
                                    setState(null)
                                }
                            }}
                        />
                    )}
                </>
            )}
            onChange={([, data]) => data}
            defaultValue={defaultValue}
            name={name}
            control={hookFormControl}
            rules={
                isRequired
                    ? {
                          validate: {
                              atLeastOne: (value) =>
                                  !!value || "Please select an option",
                          },
                      }
                    : {}
            }
        />
    )
}

export { GenderComponent }
