import React from "react";
import { useSelector } from "react-redux";

import VendorLogin from "../pages/login/Vendor";
import SPLogin from "../pages/login/SafePorter";

const withAuth = (Component) => {
  const Auth = (props) => {
    // Login data added to props via redux-store (or use react context for example)
    const isOrganizationLoggedIn = useSelector(
      (state) => state.appData.organizationKey
    );
    const isSafePorterIdLoggedIn = useSelector(
      (state) => state.appData.safePorterIdLoggedIn
    );

    // If user is not logged in, return login component
    if (isOrganizationLoggedIn == null) {
      return <VendorLogin />;
    }
    if (!isSafePorterIdLoggedIn) {
      return <SPLogin />;
    }

    // If user is logged in, return original component
    return <Component {...props} />;
  };

  // Copy getInitial props so it will run as well
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  return Auth;
};

export default withAuth;
