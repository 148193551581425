
import React, { useContext, useState } from "react"
import AppContext from "./AppContext"
import {
    Container,
    Link
} from "@material-ui/core"

const Footer = () => {
    const userSettings = useContext(AppContext)
    return (
        <Container>

            <Link href="#" onClick={() => userSettings.setLanguage("en-us")}>English</Link>&nbsp;·&nbsp;
            <Link href="#" onClick={() => userSettings.setLanguage("es-pr")}>Español</Link>&nbsp;·&nbsp;
            <Link href="#" onClick={() => userSettings.setLanguage("fr-ca")}>Français Canadien</Link>&nbsp;·&nbsp;
            <Link href="#" onClick={() => userSettings.setLanguage("pt-br")}>Português</Link>


        </Container >
    )
}

export default (Footer)
