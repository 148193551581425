import React, { useState, useContext } from "react"
import AppContext from "../../components/AppContext"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"
import {
    Box,
    Button,
    Checkbox,
    Grid,
    Link,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core"
import { ErrorMessage } from "@hookform/error-message"
import { useDispatch, useSelector } from "react-redux"
import CircularProgress from "@material-ui/core/CircularProgress"
import DocumentTitle from "react-document-title"
import { Tx, tx } from "../../translate"

import { loadSurveys } from "../../redux/actions"

const styles = (theme) => ({
    container: {
        display: "flex",
        height: "100%",
    },
    item: {
        height: "100%",
        overflowWrap: "anywhere",
    },
    vendorImage: {
        maxHeight: 100,
        width: "auto",
    },
    rightContent: {},
    underline: {
        textDecoration: "underline",
    },
    loginBox: {
        padding: '24px 0px 24px 24px',
        [theme.breakpoints.up("md")]: {
            borderLeft: `4px solid ${theme.palette.secondary.main}`,
        },
        [theme.breakpoints.down("sm")]: {
            borderTop: `4px solid ${theme.palette.secondary.main}`,
        },
    },
    checkbox: {
        padding: "0px 9px 0px 0px",
    },
})

const SafePorter = ({ classes }) => {
    const userSettings = useContext(AppContext)
    const { register, handleSubmit, setError, errors } = useForm({
        defaultValues: { "safeporter-id": "cat" },
    })
    const errorMessage = useSelector((state) => state.appData.errorMessage)
    const loading = useSelector((state) => state.appData.loadInProgress)
    const tenant = useSelector((state) => state.appData.tenant)
    const dispatch = useDispatch()
    const defaultSPID = useSelector((state) => state.appData.initialSPID)

    const [checked, setChecked] = React.useState(false)

    const handleChange = React.useCallback((event) => {
        setChecked(event.target.checked)
    }, [])

    // Scroll to top on page load (for mobile)
    React.useEffect(() => {
        const header = document.querySelector("header")

        if (header) {
            header.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }, [])

    React.useEffect(() => {
        if (errorMessage != null) {
            setError("spId", {
                type: "manual",
                message: errorMessage,
            })
        }
    }, [errorMessage])

    const onSubmit = (data) => {
        dispatch(
            loadSurveys(
                data.spId.trim(),
                tenant.tenant_id,
                userSettings.language
            )
        )
    }

    return (
        <DocumentTitle title="Login to SafePorter">
            <Grid className={classes.container} container spacing={3}>
                <Box
                    clone
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            p={3}
                            pt={6}
                        >
                            <section aria-label="about your organization, your data and MyDataProtected by SafePorter">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    pb={2}
                                >
                                    <img
                                        alt={tenant.branding?.alt}
                                        src={tenant.branding?.logo}
                                        className={classes.vendorImage}
                                        height={100}
                                        width={200}
                                    />
                                </Box>

                                <Typography
                                    component="p"
                                    align="center"
                                    color="textPrimary"
                                    gutterBottom
                                    paragraph
                                >
                                    <Tx>welcome_to</Tx> MyData<b>Protected</b>.
                                </Typography>

                                {tenant.branding?.welcome &&
                                    (userSettings.language === "en-us" ||
                                        userSettings.language === "pt-br" || userSettings.language === "fr-ca") ? (
                                    <Typography
                                        component="p"
                                        // align="center"
                                        color="textPrimary"
                                        gutterBottom
                                        paragraph
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    tenant.branding.welcome[
                                                    userSettings.language
                                                    ],
                                            }}
                                        /></Typography>
                                ) : (
                                    <>
                                        <Typography
                                            component="p"
                                            // align="center"
                                            color="textPrimary"
                                            gutterBottom
                                            paragraph
                                        >
                                            <b>
                                                {tenant.tenant_name}{" "}
                                                <Tx>company_has_provided</Tx>
                                                &nbsp;
                                                {tenant.tenant_name}{" "}
                                                <Tx>no_information</Tx>&nbsp;
                                                <Link
                                                    href="https://www.safeportersecure.com/privacynotice"
                                                    color="inherit"
                                                    underline="always"
                                                    target="_blank"
                                                >
                                                    <Tx>here</Tx>
                                                </Link>
                                                .)
                                            </b>
                                        </Typography>
                                        <Typography
                                            component="p"
                                            // align="center"
                                            color="textPrimary"
                                            gutterBottom
                                            paragraph
                                        >
                                            <b>
                                                <Tx>once_submitted</Tx>
                                            </b>
                                        </Typography>

                                        <Typography
                                            component="p"
                                            // align="center"
                                            color="textPrimary"
                                            gutterBottom
                                            paragraph
                                        >
                                            <Tx>to_delete</Tx>
                                        </Typography>
                                        <Typography
                                            component="p"
                                            // align="center"
                                            color="textPrimary"
                                            gutterBottom
                                            paragraph
                                        >
                                            <Tx>DSAR</Tx>
                                        </Typography>
                                    </>
                                )}
                            </section>
                        </Box>
                    </Grid>
                </Box>
                <Box
                    clone
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            pt={0}
                            className={classes.loginBox}
                        >
                            <section aria-label="Login to SafePorter with SafePorter ID">
                                <Typography
                                    variant="h3"
                                    component="h1"
                                    align="center"
                                    color={
                                        tenant.tenant_name === "Airbnb"
                                            ? "initial"
                                            : "primary"
                                    }
                                    gutterBottom
                                >
                                    <Tx>representation_is_critical</Tx>
                                </Typography>
                                <Box clone pb={1}>
                                    <Typography
                                        component="p"
                                        align="left"
                                        color={
                                            tenant.tenant_name === "Airbnb"
                                                ? "initial"
                                                : "textSecondary"
                                        }
                                        gutterBottom
                                    >
                                        <Tx>your2</Tx> Data
                                        <strong>Protected</strong>{" "}
                                        <Tx>ensures</Tx>
                                        <p>
                                            <Tx>safePorters_our</Tx>&nbsp;
                                            <Link
                                                href="https://www.safeportersecure.com/privacynotice"
                                                color="blue"
                                                underline="always"
                                                target="_blank"
                                            >
                                                <Tx>lbl_privacy_policy</Tx>
                                            </Link>{" "}
                                            <Tx>explain_how_process</Tx>
                                        </p>
                                        <p>
                                            <span className={classes.underline}>
                                                <Tx>personal_information</Tx>&nbsp;
                                            </span>
                                            <Tx>safePorter_collect</Tx>
                                        </p>
                                        <p>
                                            <span className={classes.underline}>
                                                <Tx>use_of_personal_info</Tx>&nbsp;
                                            </span>
                                            <Tx>
                                                safePorter_will_use_personal_info
                                            </Tx>
                                        </p>
                                        <p>
                                            <span className={classes.underline}>
                                                <Tx>please_note</Tx>
                                            </span>{" "}
                                            <Tx>completing_my_data</Tx>{" "}
                                            <Link
                                                href="https://mydataprotected.com/diversity-survey"
                                                color="inherit"
                                                underline="always"
                                                target="_blank"
                                            >
                                                <Tx>my_data_site</Tx>
                                            </Link>&nbsp;
                                            <Tx>survey_voluntary</Tx>
                                        </p>
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            size="small"
                                            className={classes.checkbox}
                                        />
                                        <Tx>by_clicking</Tx>{" "}
                                        <strong>
                                            <Tx>submit_safePorter_id</Tx>&nbsp;
                                        </strong>
                                        <Tx>completing</Tx>{" "}
                                        <Link
                                            href="https://mydataprotected.com/diversity-survey"
                                            color="inherit"
                                            underline="always"
                                            target="_blank"
                                        >
                                            <Tx>my_data_site</Tx>
                                        </Link>{" "}
                                        &nbsp;<Tx>survey</Tx>&nbsp;
                                        <strong>
                                            <span className={classes.underline}>
                                                <Tx>hereby</Tx>
                                            </span>
                                        </strong>{" "}
                                        &nbsp;<Tx>purposes</Tx>{" "}
                                        <Link
                                            href="https://www.safeportersecure.com/privacynotice"
                                            color="inherit"
                                            underline="always"
                                            target="_blank"
                                        >
                                            <Tx>lbl_privacy_policy</Tx>
                                        </Link>
                                    </Typography>
                                </Box>

                                {loading && (
                                    <Box
                                        mb={4}
                                        display="flex"
                                        justifyContent="center"
                                    >
                                        <CircularProgress color="primary" />
                                    </Box>
                                )}
                                <form
                                    autoComplete="on"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <TextField
                                        id="safeporter-id"
                                        label={tx("your_id")}
                                        variant="outlined"
                                        value={defaultSPID}
                                        fullWidth
                                        inputRef={register({
                                            required: tx("please_id"),
                                        })}
                                        name="spId"
                                        error={!!errors.spId}
                                        disabled={loading}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="spId"
                                        render={({ message }) => (
                                            <Typography color="error">
                                                {message}
                                            </Typography>
                                        )}
                                    />
                                    <Box mt={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={loading || !checked}
                                        >
                                            <Tx>submit_id</Tx>
                                        </Button>
                                    </Box>
                                </form>
                                <Box mt={3}>
                                    <Typography
                                        variant="caption"
                                        component="p"
                                        align="center"
                                        color="textSecondary"
                                        gutterBottom
                                    >
                                        <Tx>b_corp</Tx>
                                    </Typography>
                                </Box>
                            </section>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </DocumentTitle>
    )
}

SafePorter.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SafePorter)
