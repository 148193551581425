import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
    makeStyles,
} from "@material-ui/core"
import moment from "moment-timezone"
import React from "react"
import { Tx, tx } from "../translate"

const MOMENT_FORMAT = "MMMM Do, YYYY, h:mm A (z)"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.down("md")]: {
            marginTop: 50,
        },
    },
    formTitle: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 28,
        },
    },
    formSubtitle: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 22,
        },
    },
    divider: {
        borderBottom: "2px solid rgb(250, 108, 81)",
        width: "70%",
        margin: "auto",
    },
    redText: {
        color: "rgb(250, 108, 81)",
        fontWeight: "bold",
    },
    indicateActionTitle: {
        margin: "10px 0px 20px 0px",
        color: "rgb(250, 108, 81)",
        fontWeight: "bold",
    },
    updateInfoContainer: {
        border: "2px solid black",
        padding: 20,
        textAlign: "center",
        maxWidth: 420,
        margin: "auto",
    },
    updateButton: {
        backgroundColor: "#70d9b8",
        marginBottom: 20,
        width: '100%',
        "&:hover": {
            backgroundColor: "#70d9b8",
        }
    },
    updateInfoText: {
        maxWidth: 360,
        margin: "auto",
        fontWeight: "bold",
    },
    deleteContainer: {
        textAlign: "center",
        margin: "10px 0px",
    },
    deleteButton: {
        backgroundColor: "#fa6c51",
        maxWidth: 380,
        width: '100%',
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#fa6c51",
        },
    },
    dialogButtonContainer: {
        flexDirection: "column",
    },
    deleteAllButton: {
        backgroundColor: "rgb(250, 108, 81)",
        marginBottom: 20,
        width: 360,
        "&:hover": {
            backgroundColor: "rgb(250, 108, 81)",
        },
    },
}))

const DeleteDataDialog = React.forwardRef(function ({ setShowSurvey, onDelete }, ref) {
    const [open, setOpen] = React.useState(false)

    const handleClose = React.useCallback(() => {
        setOpen(false)
    }, [])

    const onUpdateData = React.useCallback(() => {
        setShowSurvey(true)
    }, [])

    const onDeleteData = React.useCallback(() => {
        onDelete()
        setOpen(false)
    }, [onDelete])

    React.useImperativeHandle(
        ref,
        () => ({
            open: () => {
                setOpen(true)
            },
        }),
        []
    )

    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Tx>delete_all</Tx>
            </DialogTitle>
            <DialogActions
                disableSpacing
                className={classes.dialogButtonContainer}
            >
                <Button
                    onClick={onDeleteData}
                    variant="outlined"
                    className={classes.deleteAllButton}
                >
                    <Tx>confirm_delete</Tx>
                </Button>
                <Button
                    onClick={onUpdateData}
                    variant="outlined"
                    className={classes.updateButton}
                >
                    <Tx>return_to_update</Tx>
                </Button>
            </DialogActions>
        </Dialog>
    )
})

export default function WelcomeBackView({ formCompletedOn, setShowSurvey, onDelete }) {
    const deleteDataDialog = React.useRef()

    const handleDeleteAllClick = React.useCallback(() => {
        deleteDataDialog.current.open()
    }, [])

    React.useEffect(() => {
        document.getElementById("contentArea")?.classList?.add("WelcomePageBgColor");
        return () => {
            document.getElementById("contentArea")?.classList?.remove("WelcomePageBgColor");
        };
    }, [])

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography
                className={classes.formTitle}
                variant="h4"
                component="h1"
                align="center"
                gutterBottom
            >
                <strong>{tx("welcome_back")}</strong>
            </Typography>
            <Typography
                variant="h5"
                align="center"
                gutterBottom
                className={classes.formSubtitle}
            >
                <strong>
                    {tx("last_submitted")}
                    <br />
                    {moment(formCompletedOn)
                        .tz(moment.tz.guess(true))
                        .format(MOMENT_FORMAT)}
                </strong>
            </Typography>
            <div className={classes.divider} />
            <Typography
                variant="h5"
                align="center"
                gutterBottom
                className={classes.indicateActionTitle}
            >
                {tx("please_indicate")}
            </Typography>
            <div className={classes.updateInfoContainer}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowSurvey(true)}
                    className={classes.updateButton}
                >
                    <strong>
                        {tx("make_changes")} <br /> {tx("div_ident_survey")}
                    </strong>
                </Button>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    className={classes.redText}
                >
                    {tx("update_info")}
                </Typography>
                <Typography
                    variant="h6"
                    align="left"
                    className={classes.updateInfoText}
                    gutterBottom
                >
                    {tx("not_full_survey")}
                    <br />
                    <br />
                    {tx("same_answers")}
                </Typography>
            </div>
            <div className={classes.deleteContainer}>
                <Button
                    onClick={handleDeleteAllClick}
                    className={classes.deleteButton}
                >
                    {tx("delete_my")}<br />{tx("d_and_i_data")}
                </Button>
            </div>
            <DeleteDataDialog
                ref={deleteDataDialog}
                setShowSurvey={setShowSurvey}
                onDelete={onDelete}
            />
        </div>
    )
}