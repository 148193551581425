import React from "react"
import { Checkbox, FormControlLabel, Radio } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Controller } from "react-hook-form"
import { tx } from "../translate"

const SearchComponent = ({
    control,
    id,
    name,
    getOptionLabel,
    getOptionSelected,
    renderInput,
    defaultValue,
    isRequired,
    isMultiple,
    rawset,
}) => {
    const options = rawset.map((option) => ({
        id: option.title.toLowerCase().replace(/ /g, "_"),
        value: option.key,
        label: option.title,
    }))
    const [isNoAnswerSelected, setIsNoAnswerSelected] = React.useState(false)
    const [state, setState] = React.useState(isMultiple ? [] : null)
    const [openDropdown, setOpenDropdown] = React.useState(false);
    const [getOption, setGetOption] = React.useState(options);

    const handleInputChange = (event, newInputValue) => {
        setGetOption(
            rawset
                .filter((option) =>
                    option.title.toLowerCase().startsWith(newInputValue)
                )
                .map((option) => ({
                    id: option.title.toLowerCase().replace(/ /g, "_"),
                    value: option.key,
                    label: option.title,
                }))
        );
        if (newInputValue.trim() !== "") {
            setOpenDropdown(true);
        } else {
            setOpenDropdown(false);
        }
    };

    const handleInputClick = () => {
        setOpenDropdown(false); // Close the dropdown when the input is clicked
    };

    return (
        <Controller
            render={({ onChange, value, ...props }) => (
                <>
                    <Autocomplete
                        id={id}
                        disabled={isNoAnswerSelected}
                        multiple={isMultiple}
                        options={getOption}
                        getOptionLabel={getOptionLabel}
                        renderInput={renderInput}
                        open={openDropdown}
                        onInputChange={handleInputChange}
                        onClick={handleInputClick}
                        onChange={(e, data) => {
                            if (isMultiple) {
                                const filteredData = data.filter(
                                    (item) => item.value !== "NoAnswer"
                                )
                                onChange(filteredData)
                                setState(filteredData)
                            } else {
                                onChange(data)
                                setState(data)
                            }
                        }}
                        getOptionSelected={getOptionSelected}
                        value={state}
                        {...props}
                    />
                    {isMultiple ? (
                        <FormControlLabel
                            key={`${name}_NoAnswer`}
                            value={{
                                id: "noanswer",
                                label: "No Answer",
                                value: "NoAnswer",
                            }}
                            control={
                                <Checkbox color="primary" name="NoAnswer" />
                            }
                            label={tx("prefer_no_answer")}
                            checked={value.some(
                                (item) => item.value === "NoAnswer"
                            )}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    const noAnswersData = [
                                        {
                                            id: "noanswer",
                                            label: "No Answer",
                                            value: "NoAnswer",
                                        },
                                    ]
                                    onChange(noAnswersData)
                                    setState(noAnswersData)
                                    setIsNoAnswerSelected(true)
                                } else {
                                    onChange([])
                                    setState([])
                                    setIsNoAnswerSelected(false)
                                }
                            }}
                        />
                    ) : (
                        <FormControlLabel
                            key={`${name}_NoAnswer`}
                            value="NoAnswer"
                            control={<Radio color="primary" name="NoAnswer" />}
                            label={tx("prefer_no_answer")}
                            checked={value?.value === "NoAnswer"}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    const noAnswersData = {
                                        id: "noanswer",
                                        label: "No Answer",
                                        value: "NoAnswer",
                                    }

                                    onChange(noAnswersData)
                                    setState(noAnswersData)
                                } else {
                                    onChange(null)
                                    setState(null)
                                }
                            }}
                        />
                    )}
                </>
            )}
            onChange={([, data]) => data}
            defaultValue={defaultValue}
            name={name}
            control={control}
            rules={
                isRequired
                    ? {
                        validate: {
                            atLeastOne: (value) =>
                                isMultiple
                                    ? value.length > 0
                                    : !!value || "Please select an option",
                        },
                    }
                    : {}
            }
        />
    )
}

export { SearchComponent }
