import React from "react"
import PropTypes from "prop-types"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    Divider,
    Grid,
    Typography,
    withStyles,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Tx, tx } from "../../translate"
import {
    SPFormSliderFieldset,
    fieldNameClean,
} from "../../components/CustomForm"

const styles = (theme) => ({
    root: {
        marginBottom: 4,
    },
    heading: {
        flexShrink: 0,
    },
    accordionHead: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    accordionBody: {
        border: `4px solid ${theme.palette.secondary.main}`,
    },
    accordionFoot: {
        // backgroundColor: theme.palette.secondary.main,
    },
    buttonPrimary: {
        color: "black",
    },
})

const Inclusion = ({ classes, inclusion, control, prefix }) => {
    const [expanded, setExpanded] = React.useState(false)

    const openNext = () => {
        setExpanded((prevProp) => prevProp + 1)
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {inclusion.categories.map((category, cIndex) => (
                    <Accordion
                        key={category.title}
                        expanded={expanded === cIndex}
                        onChange={handleChange(cIndex)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-inclusion-${cIndex}-content`}
                            id={`panel-inclusion-${cIndex}-header`}
                        >
                            <Typography
                                id={`panel-inclusion-${cIndex}-title`}
                                className={classes.heading}
                                component="h3"
                                variant="h6"
                            >
                                {category.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionBody}>
                            <Grid container justify="center">
                                <Grid item xs={10}>
                                    <SPFormSliderFieldset
                                        ariaLabelledBy={`panel-inclusion-${cIndex}-title`}
                                        control={control}
                                        name={fieldNameClean(
                                            `${prefix}${category.title}`
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button
                                className="OrangeBtn"
                                variant="contained"
                                onClick={openNext}
                            >
                                <b><Tx>next</Tx></b>
                            </Button>
                        </AccordionActions>
                    </Accordion>
                ))}
            </Grid>
        </Grid>
    )
}

Inclusion.propTypes = {
    inclusion: PropTypes.object.isRequired,
}

export default withStyles(styles)(Inclusion)
