import React from "react"
import PropTypes from "prop-types"
import {
    AppBar,
    Hidden,
    IconButton,
    Toolbar,
    withStyles,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import spLogo from "../assets/img/landing-logo.png"
import "../assets/scss/App.scss"
import { tx } from "../translate"

const styles = (theme) => ({
    secondaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    logo: {
        height: 50,
        width: "auto",
    },
})

function Header({ classes, onDrawerToggle, isLoggedIn }) {
    return (
        <AppBar position="static" color="inherit" elevation={0}>
            <Toolbar>
                {isLoggedIn && (
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onDrawerToggle}
                            edge="start"
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                )}

                {!isLoggedIn && (
                    <Hidden smDown>
                        <img
                            src={spLogo}
                            className={classes.logo}
                            alt="Brand logo MyDataProtected by SafePorter"
                        />

                    </Hidden>
                )}
                <Hidden mdUp>
                    <img
                        src={spLogo}
                        className={classes.logo}
                        alt="Brand logo MyDataProtected by SafePorter"
                    />
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Header)
