import axios from "axios";
import * as types from "./types";
// import data from './mock-data.json'

export function loadSurveys(sp_id, tenant_id, language) {
  return async function (dispatch) {
    try {
      dispatch({ type: types.FETCH_SURVEYS_INITIATED });
      const res = await axios.get(
        `${window.app_config.API.endpoints[0].endpoint}/survey?id=${encodeURIComponent(sp_id)}&tenant_id=${tenant_id}&lang=${language}`
      );
      const { data } = res;
      const surveys_keys = Object.keys(data.surveys)

      let theKey = 'diversity'
      surveys_keys.map(survey_key => {
        if (['trust_metric_method', 'state', 'last_response_time', 'include_diversity_info'].includes(survey_key)) {
        } else { theKey = survey_key }
      }
      )
      dispatch({
        type: types.FETCH_SURVEYS,
        surveys: data.surveys,
        sp_id: data.surveys[theKey].sp_id,
        destination: data.destination,
        trustMetricsValues: data.trust_categories,
        trustMetricMethod: data.trust_metric_method,
        spId: data.surveys[theKey].sp_id,
        trustMetricData: data.surveys[theKey].trust_metric_data,
        trustMetrics: data.trust_metrics,
      });
    } catch (e) {
      dispatch({ type: types.FETCH_SURVEYS_ERROR, e, sp_id });
    }
  };
}

export function setNewSurveySubmit() {
  return { type: types.SET_NEW_SURVEY_SUBMIT };
}

export function onSideBarAction() {
  return { type: types.ON_SIDEBAR };
}

export function reloadSurveys(sp_id, tenant_id, language) {
  return async function (dispatch) {
    dispatch({
      type: types.REFETCH_SURVEYS_INITIATED,
    });
    try {
      const res = await axios.get(
        `${window.app_config.API.endpoints[0].endpoint}/survey?id=${sp_id}&tenant_id=${tenant_id}&lang=${language}`
      );
      const { data } = res;

      dispatch({
        type: types.RELOAD_FETCH_SURVEYS,
        surveys: data.surveys,
        trustMetrics: data.trust_metrics,
      });
    } catch (e) {
      dispatch({ type: types.RELOAD_FETCH_SURVEYS_ERROR });
    }
  };
}

export function selectSurvey(key) {
  return { type: types.SELECT_SURVEY, key };
}

export function submitSurvey(data, api, reloadSurveysData = undefined, surveyType = null) {
  return async function (dispatch) {
    try {
      dispatch({ type: types.SUBMIT_SURVEYS_INITIATED });
      const headers = {
        "Content-Type": "text/json",
      };
      const res = await axios.post(`${api}/survey`, data, headers);
      if (reloadSurveysData) {
        dispatch(reloadSurveys(reloadSurveysData?.spId, reloadSurveysData?.tenant_id, reloadSurveysData?.language))
      }
      dispatch({ type: types.SUBMIT_SURVEY, res, surveyType });
    } catch (e) {
      console.log("submit error", e);
      dispatch({ type: types.SUBMIT_SURVEY_ERROR, e });
    }
  };
}

export function setOrganizationKey(key) {
  return { type: types.SET_ORGANIZATION_KEY, key };
}

export function getTenant(clientKey) {
  return async function (dispatch) {
    try {
      dispatch({ type: types.FETCH_TENANT_INITIATED });
      const res = await axios.get(
        `${window.app_config.API.endpoints[0].endpoint}/tenantkey?clientKey=${clientKey}`
      );
      const { data } = res;
      dispatch({ type: types.FETCH_TENANT, data, clientKey });
    } catch (e) {
      dispatch({ type: types.FETCH_TENANT_ERROR, e, clientKey });
    }
  };
}

export function setEngagement(engagement, key) {
  return async function (dispatch) {
    dispatch({ type: types.SET_ENGAGEMENT, engagement, key });
  }
}
