import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Container, withStyles } from "@material-ui/core";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import NavigationBar from "./components/NavigationBar";
import SurveysPage from "./pages/Surveys";
import FeedbackPage from "./pages/feedback";
import Header from "./components/Header";
import AppContext from "./components/AppContext";
import CookieConsent from "react-cookie-consent";
import { language_code } from "./translate";
import "./assets/scss/App.scss";
import Home from "./pages/home";
import EngagementSurvey from "./pages/engagementSurvey";

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  app: {
    backgroundColor: "white",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  appMain: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
  },
};

function createAppziScript() {
  const script = document.createElement("script");
  script.src = "https://w.appzi.io/w.js?token=J0Pxf";
  script.async = true;
  return script;
}

const appzi = createAppziScript();

const App = ({ classes }) => {
  // global language setting
  const [lang, setLang] = React.useState(language_code(navigator.language));
  const [trustMetricsAccepted, setTrustMetricsAccepted] = React.useState(false)
  const setLanguage = (language) => {
    setLang(language);
  };

  const userSettings = {
    language: lang,
    setLanguage,
    tmdAccepted: trustMetricsAccepted,
    setTrustMetricsAccepted
  };

  const [isDiversityDisabled, setIsDiversityDisabled] = React.useState(false);
  const [isFeedbackDisabled, setIsFeedbackDisabled] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const surveySubmitted = useSelector((state) => state.appData.surveySubmitted);
  const isFeedbackSurveyAvailable = useSelector(
    (state) => !!state.appData.surveys?.inclusion
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isOrganizationLoggedIn = useSelector(
    (state) => state.appData.organizationKey
  );
  const isSafePorterIdLoggedIn = useSelector(
    (state) => state.appData.safePorterIdLoggedIn
  );
  const isLoggedIn = !!(isOrganizationLoggedIn && isSafePorterIdLoggedIn);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const diversitySurveyTime = useSelector(
    (state) => state?.appData?.diversitySurvey
  );
  React.useEffect(() => {
    if (
      diversitySurveyTime &&
      diversitySurveyTime?.last_response_time === undefined
    ) {
      setIsDiversityDisabled(true);
    }
  }, [diversitySurveyTime, isDiversityDisabled]);

  let appData = useSelector((state) => state.appData);
  appData.initialSPID = new URLSearchParams(window.location.search).get("id");
  // Load Appzi feedback widget
  React.useEffect(() => {
    if ((isLoggedIn && !isMobile) || (isMobile && surveySubmitted)) {
      if (!document.body.contains(appzi)) {
        document.body.appendChild(appzi);

        const appziElements = document.querySelectorAll("[id^=appzi-launch]");
        for (let i = 0; i < appziElements.length; i++) {
          appziElements[i].style.display = "block";
        }
      }
    } else {
      if (document.body.contains(appzi)) {
        document.body.removeChild(appzi);

        const appziElements = document.querySelectorAll("[id^=appzi-launch]");
        for (let i = 0; i < appziElements.length; i++) {
          appziElements[i].style.display = "none";
        }
      }
    }
  }, [isLoggedIn, isMobile, surveySubmitted]);

  return (
    <AppContext.Provider value={userSettings}>
      <div className={classes.root}>
        <NavigationBar
          open={mobileOpen}
          onClose={handleDrawerToggle}
          isLoggedIn={isLoggedIn}
          isDiversityDisabled={isDiversityDisabled}
          isFeedbackDisabled={isFeedbackDisabled}
          appData={appData}
        />
        <div className={classes.app} id={isLoggedIn ? "contentArea" : ""}>
          <Header
            id="Header"
            onDrawerToggle={handleDrawerToggle}
            isLoggedIn={isLoggedIn}
            lang={lang}
            setLang={setLang}
          />
          <Container id="sp-main" role="main" className={classes.appMain}>
            <Switch>
              <Route path="/home">
                <Home isDiversityLastResponse={isDiversityDisabled} />
              </Route>
              <Route path="/diversity-survey">
                <SurveysPage setIsDiversityDisabled={setIsDiversityDisabled} />
              </Route>
              {isFeedbackSurveyAvailable && (
                <Route path="/feedback-survey">
                  <FeedbackPage setIsFeedbackDisabled={setIsFeedbackDisabled} />
                </Route>
              )}
              <Route path="/engagement-survey">
                <EngagementSurvey />
              </Route>
              <Redirect to="/home" />
            </Switch>
            <CookieConsent
              location="bottom"
              declineButtonText="I acknowledge"
              enableDeclineButton
              setDeclineCookie={false}
              flipButtons
              buttonStyle={{
                display: "none",
              }}
              declineButtonStyle={{
                backgroundColor: "#FFD42D",
                color: "black",
              }}
            >
              This website does not use cookies.
            </CookieConsent>
          </Container>
        </div>
      </div>
    </AppContext.Provider>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
