import React from "react"
import {
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Grid,
    Radio,
    TextField,
    makeStyles,
    Typography,
    RadioGroup,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Controller } from "react-hook-form"
import { tx } from "../translate"

const useStyles = makeStyles({
    typography: {
        marginTop: 10,
    },
    addBorder: {
        border: '4px solid #70d9b8',
        padding: '10px',
        borderRadius: '20px',
    }
})

const defaultMultiRacialValues = {
    id: "multi-racial",
    label: "Multi-Racial",
    value: "Multi-Racial",
    isMultiRacial: true,
}

const MultiRacialComponent = ({
    title,
    name,
    hookFormControl,
    isRequired,
    rawset,
    defaultValue = null,
}) => {
    const options = rawset.map((option) => ({
        id: option.title.toLowerCase().replace(/ /g, "_"),
        value: option.key,
        label: option.title,
        isMultiRacial: option.multi_racial,
    }))

    const multiRacialKey = rawset.find((option) => option.multi_racial).key

    const [nameOfChecked, setNameOfChecked] = React.useState({
        ...defaultMultiRacialValues,
        selectedValues: [multiRacialKey],
    })
    const [state, setState] = React.useState(null)

    const addRemoveCheckboxValues = React.useCallback(
        (checkEvent, nameEvent) => {
            if (checkEvent) {
                setNameOfChecked(
                    (prev) => prev,
                    nameOfChecked.selectedValues.push(nameEvent)
                )
            } else {
                const indexOfRemoved = nameOfChecked.selectedValues.indexOf(
                    nameEvent
                )
                setNameOfChecked(
                    (prev) => prev,
                    nameOfChecked.selectedValues.splice(indexOfRemoved, 1)
                )
            }
            return nameOfChecked
        },
        [nameOfChecked]
    )

    const classes = useStyles()

    return (
        <Controller
            render={({ onChange, value, ...props }) => (
                <>
                    <RadioGroup row aria-label={`radio options for ${title}`}
                        onChange={(e, data) => {
                            if (data === 'NoAnswer') {
                                const noAnswersData = {
                                    id: "noanswer",
                                    label: "No Answer",
                                    value: "NoAnswer",
                                }

                                onChange(noAnswersData)
                                setState(noAnswersData)
                            } else {
                                const va = JSON.parse(data);
                                onChange(va)
                                setState(va)
                                setNameOfChecked({
                                    ...defaultMultiRacialValues,
                                    selectedValues: [multiRacialKey],
                                })

                            }

                        }}
                        {...props}
                    >
                        {options.map((option) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                key={`${title}_${option.title}`}
                            >
                                <Box display="flex" alignItems="center">
                                    <FormControlLabel
                                        key={option.key}
                                        value={JSON.stringify(option)}
                                        control={<Radio color="primary" />}
                                        label={option.label}
                                    />
                                </Box>
                            </Grid>
                        ))}
                        <Grid item xs={12} md={6} key={`${title}_NoAnswer`}>
                            <FormControlLabel
                                value="NoAnswer"
                                control={<Radio color="primary" />}
                                label={tx("prefer_no_answer")}
                            />
                        </Grid>
                    </RadioGroup>
                    {value?.isMultiRacial && (
                        <div className={classes.addBorder}>
                            <Typography
                                component="h2"
                                variant="h6"
                                align="center"
                                gutterBottom
                                className={classes.typography}
                            >
                                {tx("select_all_that_apply")}
                            </Typography>
                            <Controller
                                control={hookFormControl}
                                name={name}
                                defaultValue={defaultValue}
                                render={(props) => (
                                    <FormGroup row>
                                        {options
                                            .filter(
                                                (option) =>
                                                    !option.isMultiRacial
                                            )
                                            .map((option) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    key={`_${option.label}`}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <FormControlLabel
                                                            value={option.value}
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    name={
                                                                        option.value
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        props.onChange(
                                                                            addRemoveCheckboxValues(
                                                                                e
                                                                                    .target
                                                                                    .checked,
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        )
                                                                    }}
                                                                />
                                                            }
                                                            label={option.label}
                                                        />
                                                    </Box>
                                                </Grid>
                                            ))}
                                    </FormGroup>
                                )}
                            />
                        </div>
                    )}
                </>
            )}
            onChange={([, data]) => data}
            defaultValue={defaultValue}
            name={name}
            control={hookFormControl}
            rules={
                isRequired
                    ? {
                        validate: {
                            atLeastOne: (value) =>
                                !!value || "Please select an option",
                        },
                    }
                    : {}
            }
        />
    )
}

export { MultiRacialComponent }
