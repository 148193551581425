export const translations = {
    spid_tied_to: {
        'en-us': "DataProtected does not know your name, your email, or anything else about you except the organization your SafePorter ID is tied to and the information you provide here.",
        'da': "DataProtected har ikke adgang til dit navn, din e-mailadresse eller andre oplysninger om dig med undtagelse af den organisation, som dit SafePorterID er knyttet til, samt de oplysninger, du angiver her.",
        'de': "DataProtected hat keinen Zugriff auf Ihren Namen, Ihre E-Mail-Adresse oder andere Informationen über Sie mit Ausnahme der Organisation, die mit Ihrer SafePorter ID verknüpft ist, und der Informationen, die Sie hier bereitstellen.",
        'zh-cn': "除了您的 SafePorterID 所绑定的组织信息以及您在这里所提供的信息外，DataProtected 并不知晓您的姓名、电子邮件或其他任何有关您的信息。",
        'pt-br': "A DataProtected não sabe o seu nome, o seu endereço de e-mail, ou qualquer outro dado a seu respeito, exceto a organização à qual o seu SafePorter ID está associado, e as informações fornecidas por si neste inquérito.",
        'fr': "DataProtected ne connaît pas votre nom, votre e-mail ou quoi que ce soit d'autre à votre sujet, à l'exception de l'organisation à laquelle votre SafePorterID est lié et des informations que vous fournissez ici.",
        'es-pr': "DataProtected no conocerá su nombre, su correo electrónico ni ninguna otra cosa sobre usted, excepto la organización a la que está vinculado su ID de SafePorter y la información que usted proporcione aquí."
    },
    change_any_time: {
        'en-us': "Your SafePorter ID runs concurrent with the length of your employment or engagement with the DataProtected client, but you have the ability to delete or change your information at any time.",
        'da': "Dit SafePorter ID gælder under hele din ansættelse eller dit engagement med vores DataProtected-klient, men du kan når som helst slette eller ændre dine oplysninger.",
        'de': "Ihre SafePorter ID behält ihre Gültigkeit für die Dauer Ihres Arbeitsverhältnisses oder Ihrer Zusammenarbeit mit unserem DataProtected-Kunden. Sie haben aber die Möglichkeit, Ihre Daten jederzeit zu ändern oder zu löschen.",
        'zh-cn': "您的 SafePorter ID 与您和我们的 DataProtected 客户的工作年限或参与时限相同，但您可以随时删除或更改您的信息。",
        'pt-br': "O seu SafePorter ID será válido durante o termo do seu emprego ou acordo com o nosso cliente DataProtected, mas poderá eliminar ou alterar os seus dados a qualquer momento.",
        'fr': "Votre ID SafePorter fonctionne en même temps que la durée de votre emploi ou de votre engagement avec notre client DataProtected, mais vous avez la possibilité de supprimer ou de modifier vos informations à tout moment.",
        'es-pr': "Su ID de SafePorter se ejecuta simultáneamente con la duración de su empleo o compromiso con el cliente DataProtected, pero usted tiene la capacidad de eliminar o cambiar su información en cualquier momento."
    },
    your_info: {
        'en-us': "Your personal information is not accessible to your organization, or anyone else, except in the de-identified aggregate.",
        'da': "Dine personoplysninger er ikke tilgængelige for din organisation eller andre på nogen anden måde end i anonymiseret og aggregeret format.",
        'de': "Ihre persönlichen Daten sind weder für Ihre Organisation noch für andere zugänglich – außer in anonymisierter und aggregierter Form.",
        'zh-cn': "您所在的组织或其他任何人都无法访问您的个人信息，他们只可访问不包含身份识别信息的汇总数据。",
        'pt-br': "Os seus dados pessoais não estão acessíveis à sua organização, ou a qualquer outra entidade, estando apenas acessíveis no agregado anonimizado.",
        'fr': "Vos informations personnelles ne sont pas accessibles à votre organisation, ni à personne d'autre, sauf dans l'ensemble anonymisé.",
        'es-pr': "Su información personal no es accesible para su organización, ni para ninguna otra persona, excepto en el agregado anonimizado."
    },
    commited_to_protect: {
        'en-us': "SafePorter has committed to a duty to protect your data with an undivided loyalty to you as the person providing the information into our care.",
        'da': "SafePorter har forpligtet sig til at beskytte dine oplysninger med fuldstændig loyalitet over for dig, der lægger dine oplysninger i vores hænder.",
        'de': "SafePorter verpflichtet sich dem Schutz Ihrer Daten. Unsere Loyalität gilt dabei ausschließlich Ihnen – denn Sie vertrauen uns Ihre Daten an.",
        'zh-cn': "SafePorter 会尽职尽责地保护您的数据，忠诚坚定地对待您所提供给我们的信息。",
        'pt-br': "A SafePorter compromete-se ao dever de proteger os seus dados, mostrando-lhe a máxima lealdade por nos confiar informações a seu respeito.",
        'fr': "SafePorter s'est engagé à protéger vos données avec une loyauté sans partage envers vous en tant que personne fournissant les informations qui nous sont confiées.",
        'es-pr': "SafePorter se ha comprometido a proteger sus datos con una lealtad indivisa hacia usted como la persona que proporciona la información a nuestro cuidado."
    },
    lbl_privacy_policy: {
        'en-us': "Privacy Policy",
        'da': "Fortrolighedspolitik",
        'de': "Datenschutzerklärung",
        'zh-cn': "隐私保护政策",
        'pt-br': "Política de privacidade",
        'fr': "Politique de confidentialité",
        'es-pr': "Política de privacidad"
    },
    representation_is_critical: {
        'en-us': "Representation is critical to inclusion and equity.",
        'da': "Repræsentation er afgørende for inklusion og retfærdig behandling",
        'de': "Sichtbarkeit ist der Schlüssel zu Inklusion und Gleichstellung.",
        'zh-cn': "要获得包容与公平，代表性至关重要。",
        'pt-br': "A representação é fundamental para a inclusão e a equidade.",
        'fr': "La représentation est essentielle à l'inclusion et à l'équité.",
        'es-pr': "La representación es fundamental para la inclusión y la equidad."
    },
    organization_key: {
        'en-us': "Organization Key",
        'da': "Organisationsnøgle",
        'de': "Zugangscode Ihrer Organisation",
        'zh-cn': "组织密匙",
        'pt-br': "Senha da Organização",
        'fr': "Clé d'organisation",
        'es-pr': "Clave de la organización"
    },
    your_id: {
        'en-us': "Your SafePorter ID",
        'da': "Dit SafePorter ID",
        'de': "Ihre SafePorter ID",
        'zh-cn': "您的 SafePorter ID",
        'pt-br': "O seu SafePorter ID",
        'fr': "Votre identifiant SafePorter est :",
        'es-pr': "Su ID de SafePorter es:"
    },
    please_id: {
        'en-us': "Please enter your ID",
        'da': "Indtast dit SafePorter ID",
        'de': "Bitte geben Sie Ihre SafePorter ID ein",
        'zh-cn': "请输入您的 SafePorter ID",
        'pt-br': "Por favor, insira o seu SafePorter ID",
        'fr': "Veuillez saisir votre identifiant",
        'es-pr': "Por favor, introduzca su documento de identidad"
    },
    submit_key: {
        'en-us': "Submit Key",
        'da': "Send",
        'de': "Weiter",
        'zh-cn': "提交",
        'pt-br': "Submeter senha",
        'fr': "Soumettre la clé",
        'es-pr': "Clave de envío"
    },
    submit_id: {
        'en-us': "Submit ID",
        'da': "Send SafePorter ID",
        'de': "Weiter",
        'zh-cn': "提交 SafePorter ID",
        'pt-br': "Submeter SafePorter ID",
        'fr': "Soumettre l'ID",
        'es-pr': "ID de envío"
    },
    b_corp: {
        'en-us': "SafePorter is a B Corp with the mission of furthering both representation and organizational accountability while protecting the privacy of individuals and minimizing data risk.",
        'da': "SafePorter er et B Corp, der har som mål at fremme både repræsentation og organisatorisk ansvarlighed og samtidig værne om enkeltpersoners privatliv og minimere datarisici.",
        'de': "SafePorter ist eine zertifizierte B Corp mit der Mission, die Sichtbarkeit von Vielfalt und die Verantwortung von Organisationen voranzutreiben und gleichzeitig die Privatsphäre jeder und jedes Einzelnen zu schützen und das Datenrisiko zu minimieren.",
        'zh-cn': "SafePorter 是一家共益企业，其使命是在保护个人隐私以及最大限度地减少数据风险的同时，进一步提高代表性、加强组织当责。",
        'pt-br': "A SafePorter é uma B Corp (Certified B Corporation), cuja missão é a promoção da representação e da responsabilidade das organizações, bem como da proteção da privacidade dos indivíduos e da minimização do risco de partilha de dados.",
        'fr': "SafePorter est une B Corp dont la mission est de renforcer à la fois la représentation et la responsabilité organisationnelle tout en protégeant la vie privée des individus et en minimisant les risques liés aux données.",
        'es-pr': "SafePorter es una B Corp con la misión de fomentar tanto la representación como la responsabilidad de la organización, y a su vez, protegiendo la privacidad de las personas y minimizando el riesgo de los datos."
    },
    your: {
        'en-us': "Your",
        'da': "Dine",
        'de': "Ihr",
        'zh-cn': "您在",
        'pt-br': "Os seus",
        'fr': "Votre",
        'es-pr': "Usted"
    },
    your2: {
        'en-us': "Your",
        'da': "Dine",
        'de': "Ihr",
        'zh-cn': "您在",
        'pt-br': "Sua contribuição ",
        'fr': "Votre",
        'es-pr': "Usted"
    },
    welcome_to: {
        'en-us': "Welcome to",
        'da': "Velkommen til",
        'de': "Willkommen bei",
        'zh-cn': "欢迎使用",
        'pt-br': "Bem-vindo a",
        'fr': "Bienvenue à",
        'es-pr': "Bienvenidos a"
    },
    ensures: {
        'en-us': "input ensures you are represented without compromising your privacy.",
        'da': "input sikrer, at du bliver repræsenteret uden at gå på kompromis med dit privatliv.",
        'de': "Beitrag sorgt für Ihre Sichtbarkeit – ohne Kompromisse bei der Privatsphäre einzugehen.",
        'zh-cn': "中所输入的信息，保证您能够在隐私不受损害的前提下展现您的代表性。",
        'pt-br': "garante que você seja representado sem comprometer sua privacidade.",
        'fr': "l'entrée garantit que vous êtes représenté sans compromettre votre vie privée.",
        'es-pr': "La entrada garantiza que esté representado sin comprometer su privacidad."
    },
    safePorters_our: {
        'en-us': "SafePorter’s (“our” and “we”)",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "A",
        'fr': "La",
        'es-pr': "SafePorter's (”nuestro” y ”nosotros”)"
    },
    explain_how_process: {
        'en-us': "explains how we process your personal information and the control you have over your personal information. Here's an overview:",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "da SafePorter (“nossa” e “nós”) explica como processamos suas informações pessoais e o controle que você tem sobre suas informações pessoais. Aqui está uma visão geral:",
        'fr': "explique comment nous traitons vos informations personnelles et le contrôle que vous avez sur vos informations personnelles. En voici un aperçu :",
        'es-pr': "Explica cómo procesamos su información personal y el control que tiene sobre dicha información. Aquí hay una descripción general:"
    },
    personal_information: {
        'en-us': "Personal information collected:",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "",
        'fr': "Renseignements personnels recueillis :",
        'es-pr': "Información personal recopilada:"
    },
    safePorter_collect: {
        'en-us': "SafePorter will collect your SafePorter ID, name, email address, feedback and responses to questions such as relating to your race, ethnicity, sexual orientation and gender identity",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "",
        'fr': "SafePorter recueillera votre identifiant SafePorter, votre nom, votre adresse électronique, vos commentaires et vos réponses à des questions telles que votre race, votre origine ethnique, votre orientation sexuelle et votre identité de genre.",
        'es-pr': "SafePorter recopilará su ID de SafePorter, nombre, dirección de correo electrónico, comentarios y respuestas a preguntas relacionadas con su raza, etnia, orientación sexual e identidad de género"
    },
    use_of_personal_info: {
        'en-us': "Use of personal information: ",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "Informações pessoais coletadas:",
        'fr': "Utilisation des renseignements personnels :",
        'es-pr': "Uso de la información personal:"
    },
    safePorter_will_use_personal_info: {
        'en-us': "SafePorter will use your personal information to provide and administer the MyDataProtected.com surveys and feedback intake forms, and to provide anonymised and aggregated insights on diversity, inclusion, and other feedback to employers that allow them insight into the status, progress, and effectiveness of their programs and initiatives ",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "SafePorter coletará sua identificação SafePorter, nome, endereço de email, feedback e respostas a perguntas como relacionadas a sua raça, etnia, orientação sexual e identidade de gênero.",
        'fr': "SafePorter utilisera vos renseignements personnels pour fournir et administrer les sondages et les formulaires de réception des commentaires de MyDataProtected.com, et pour fournir aux employeurs des informations anonymes et agrégées sur la diversité, l'inclusion et d'autres commentaires qui leur permettent de connaître l'état, les progrès et l'efficacité de leurs programmes et initiatives.",
        'es-pr': "SafePorter utilizará su información personal para proporcionar y administrar las encuestas MyDataProtected.com y los formularios de retroalimentación, y para proporcionar información anónima y agregada sobre diversidad, inclusión y otros comentarios a los empleadores que les permitan conocer el estado, el progreso y la eficacia de sus programas e iniciativas"
    },
    please_note: {
        'en-us': "Please note:",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "Observação:",
        'fr': "Veuillez noter",
        'es-pr': "Tenga en cuenta:"
    },
    completing_my_data: {
        'en-us': "Completing the",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "A conclusão da pesquisa",
        'fr': "que le fait de répondre à l'enquête de",
        'es-pr': "Completando el"
    },
    my_data_site: {
        'en-us': "MyDataProtected.com",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "",
        'fr': "",
        'es-pr': "MyDataProtected.com"
    },
    survey_voluntary: {
        'en-us': " survey is entirely voluntary and will not affect your employment or relationship with your organization in any way. You may freely decide to give or refuse to consent. If you give your consent, you remain entitled to withdraw it at any time and without providing a reason. Please note that any prior aggregation of your personal information before the time of your withdrawal of your consent will not be impacted. You may withdraw your consent at any time by logging in again and selecting Delete My Data.",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "é totalmente voluntária e não afetará de forma alguma seu emprego ou relacionamento com sua organização. Você pode decidir livremente dar ou recusar seu consentimento. Se você der seu consentimento, você continua tendo o direito de retirá-lo a qualquer momento e sem fornecer um motivo. Observe que qualquer agregação prévia de suas informações pessoais antes do momento da retirada de seu consentimento não será afetada. Você pode retirar seu consentimento a qualquer momento, fazendo novamente o login e selecionando Excluir Meus Dados.",
        'fr': "est entièrement volontaire et n'affectera en rien votre emploi ou votre relation avec votre organisation. Vous pouvez décider librement de donner ou de refuser votre consentement. Si vous donnez votre consentement, vous restez en droit de le retirer à tout moment et sans fournir de raison. Veuillez noter que toute agrégation préalable de vos informations personnelles avant le moment du retrait de votre consentement ne sera pas impactée. Vous pouvez retirer votre consentement à tout moment en vous connectant à nouveau et en sélectionnant Supprimer mes données.",
        'es-pr': "La encuesta es totalmente voluntaria y no afectará su empleo ni su relación con su organización de ninguna manera. Usted puede decidir libremente otorgar o negar su consentimiento. Si usted otorga su consentimiento, tendrá el derecho a retirarlo en cualquier momento y sin indicar los motivos. Tenga en cuenta que cualquier actualización previa de su información personal antes del momento en que retire su consentimiento no se verá afectada. Puede retirar su consentimiento en cualquier momento iniciando sesión de nuevo y seleccionando Eliminar mis datos."
    },
    by_clicking: {
        'en-us': "By clicking",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "Ao clicar em",
        'fr': "En cliquant sur",
        'es-pr': "Al hacer clic en"
    },
    submit_safePorter_id: {
        'en-us': "“Submit SafePorter ID”",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "“Enviar ID SafePorter”",
        'fr': "“Soumettre l'identifiant SafePorter”",
        'es-pr': "Enviar ID de SafePorter"
    },
    completing: {
        'en-us': "and completing the",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "e preencher a pesquisa",
        'fr': "et en répondant à l'enquête",
        'es-pr': "y completando el"
    },
    survey: {
        'en-us': "survey",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "pesquisa",
        'fr': ",",
        'es-pr': "encuesta"
    },
    hereby: {
        'en-us': "I hereby give my explicit consent",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "dou meu consentimento explícito ",
        'fr': "je donne mon consentement explicite",
        'es-pr': "Por la presente otorgo mi consentimiento explícito"
    },
    purposes: {
        'en-us': "to the use of my personal information (including special category personal information relating to diversity) for the purposes outlined above and in accordance with the",
        'da': "",
        'de': "",
        'zh-cn': "",
        'pt-br': "para o uso de minhas informações pessoais (incluindo informações pessoais de categoria especial relativas à diversidade) para os propósitos descritos acima e de acordo com a",
        'fr': "à l'utilisation de mes informations personnelles (y compris les informations personnelles de catégorie spéciale relatives à la diversité) aux fins décrites ci-dessus et conformément à la",
        'es-pr': "al uso de mi información personal (incluyendo la información personal de categoría especial relacionada con la diversidad) para los fines descritos anteriormente y de acuerdo con el"
    },
    company_has_provided: {
        'en-us': "has provided this survey through MyDataProtected to ensure that your privacy is paramount and maintained in the collection of diversity related data. Only aggregate data and insights are available to",
        'da': "har gjort denne undersøgelse tilgængelig via MyDataProtected for at sikre, at dit privatliv opretholdes og beskyttes i forbindelse med indsamlingen af mangfoldighedsrelaterede oplysninger. Kun aggregerede oplysninger og konklusioner gøres tilgængelige for",
        'de': "stellt diese Umfrage über MyDataProtected zur Verfügung, damit Ihre Privatsphäre bei der Erhebung von Daten zur Diversität sichergestellt ist und höchsten Schutz genießt.",
        'zh-cn': "通过 MyDataProtected 提供这项调查，以确保在收集多元化相关数据时，也能将您的隐私放在首位并加以维护。只有汇总的数据和见解才会提供给",
        'pt-br': "proporcionou este inquérito através do MyDataProtected para dar prioridade à sua privacidade e garantir a proteção da mesma durante a recolha de dados relativos à diversidade. apenas terá acesso aos dados agregados e à sua análise",
        'fr': "a fourni ce sondage par l'entremise de MyDataProtected afin de s'assurer que votre confidentialité est respectée et maintenue dans la collecte de données relative à la diversité. ",
        'es-pr': "ha proporcionado esta encuesta a través de MyDataProtected para garantizar que su privacidad sea primordial y se mantenga en la recopilación de datos relacionados con la diversidad. Solo los datos adicionales y la información están disponibles para"
    },
    no_information: {
        'en-us': "and no information is provided where it would potentially identify an individual. ( Learn more",
        'da': "og der videregives ingen oplysninger, som kan anvendes til at identificere et bestemt individ. (Læs mere",
        'de': "erhält Daten und Ergebnisse nur in aggregierter Form und keine Informationen, anhand derer eine Person identifiziert werden kann. (Erfahren Sie",
        'zh-cn': "而且不会提供任何可用于识别个人身份的信息。（欲了解更多，",
        'pt-br': "e não será fornecida nenhuma informação que possibilite a identificação de um indivíduo (Saiba mais",
        'fr': "ne disposera que de données agrégées et aucune information qui pourrait permettre d'identifier une personne lui sera fournie. (Pour en savoir plus, ",
        'es-pr': "y no se proporciona ninguna información que pueda identificar a una persona. ( Más información"
    },
    here: {
        'en-us': "here",
        'da': "her",
        'de': "hier mehr)",
        'zh-cn': "点击这里。",
        'pt-br': "aqui",
        'fr': "cliquez ici",
        'es-pr': "aquí"

    },
    once_submitted: {
        'en-us': "Once you have submitted your survey, no one at your organization will be able to view or access the data you have provided here, and it is tied in our system only to numbers associated with your SPID. If you wish or need to change your answers after submission of your My Diversity DataProtected survey, you will be able to submit any new answer(s) without answering the other questions and the prior data will be overwritten.",
        'da': "Når du har indsendt din besvarelse, kan ingen i din organisation se eller få adgang til de oplysninger, du har angivet her, og i vores system er oplysningerne kun tilknyttet tal, der er relateret til dit SPID. Hvis du ønsker at ændre dine svar, efter at du har indsendt din besvarelse af My Diversity DataProtected-undersøgelsen, kan du indsende nye svar til de spørgsmål, du vil ændre, uden at besvare de øvrige spørgsmål. De tidligere oplysninger bliver overskrevet.",
        'de': "In Ihrer Organisation kann niemand Ihre Antworten in der Umfrage sehen oder darauf zugreifen. Zusätzlich sind Ihre Antworten in unserem System nur unter einem Code abgespeichert, der mit Ihrer SafePorter ID verknüpft ist. Wenn Sie Ihre Antworten nach dem Speichern der My Diversity DataProtected-Umfrage ändern wollen, müssen Sie nicht alle Fragen erneut beantworten – ändern Sie einfach nur die entsprechenden Antworten und speichern Sie die Umfrage. Dabei werden die zuvor gespeicherten Antworten überschrieben.",
        'zh-cn': "提交了调查回复后，您组织中的任何人都绝对无法查看或访问您在这里所提供的回答。提交 My Diversity DataProtected 调查后，如果您希望或需要更改您的回答，您可以提交新的回答且无需回答其他问题，之前的回答将被新回答所覆盖。",
        'pt-br': "Uma vez concluído o inquérito, nenhum membro da sua organização poderá visualizar ou aceder aos dados aqui fornecidos, e estes apenas estarão ligados no nosso sistema aos números associados ao seu SPID (SafePorter ID). Se quiser ou precisar de alterar as suas respostas após a conclusão do seu inquérito My Diversity da DataProtected, poderá fornecer novas respostas sem ter de responder às outras perguntas, e os dados anteriormente fornecidos serão substituídos.",
        'fr': "Une fois que vous avez soumis votre sondage, personne au sein de l’organisation ne pourra voir ou accéder aux données que vous avez fournies ici. Le sondage est lié à notre système uniquement à travers les numéros associés à votre SPID. Si vous souhaitez ou devez modifier vos réponses après la soumission de votre sondage « My Diversity DataProtected », vous pourrez soumettre une ou plusieurs nouvelles réponses sans répondre aux autres questions ; les données antérieures seront immédiatement remplacées.",
        'es-pr': "Una vez que haya enviado su encuesta, nadie en su organización podrá ver o acceder a los datos que usted ha proporcionado aquí, y está vinculado en nuestro sistema solo a los números asociados con su SPID. Si desea o necesita cambiar sus respuestas después de enviar su encuesta My Diversity DataProtected, podrá enviar cualquier respuesta nueva sin responder a las otras preguntas y los datos anteriores se sobrescribirán."
    },
    to_delete: {
        'en-us': "To delete all information you have submitted previously to MyDataProtected you may at any time submit a full survey and opt out of all questions. This will overwrite any prior answers and delete your information from MyDataProtected.",
        'da': "Hvis du vil have slettet alle de oplysninger, du har indsendt til MyDataProtected, kan du når som helst indsende et komplet spørgeskema, hvor du ikke besvarer nogen spørgsmål. Dette overskriver alle tidligere svar og sletter dine oplysninger hos MyDataProtected.",
        'de': "Sie können Ihre in MyDataProtected gespeicherten Daten jederzeit löschen. Öffnen Sie dazu die Umfrage und entfernen Sie Ihre Antworten bei allen Fragen. Beim Speichern werden alle zuvor gespeicherten Antworten überschrieben und Ihre Daten somit von MyDataProtected gelöscht.",
        'zh-cn': "若要删除您先前提交给 MyDataProtected 的所有信息，您可以随时提交一份完整的调查并选择不回复所有问题。这样即可覆盖之前的任何回答，并从 MyDataProtected 中删除您的信息。",
        'pt-br': "Para eliminar todas as informações previamente fornecidas no MyDataProtected poderá, a qualquer momento, repetir o inquérito e optar por não responder a nenhuma das perguntas. Substituirá, assim, todas as respostas e eliminará do MyDataProtected todos os dados previamente fornecidos.",
        'fr': "Pour supprimer toutes les informations que vous avez soumises précédemment à MyDataProtected, vous pouvez à tout moment soumettre un sondage complet et refuser de répondre à toutes les questions. Cela effacera toutes les réponses précédentes et supprimera vos informations de MyDataProtected.",
        'es-pr': "Para eliminar toda la información que haya enviado previamente a MyDataProtected, podrá enviar en cualquier momento una encuesta completa y optar por no recibir todas las preguntas. Esto sobrescribirá cualquier respuesta anterior y eliminará su información de MyDataProtected."

    },
    DSAR: {
        'en-us': "SafePorter and the MyDataProtected site do not have any access to your name, contact information or employee/student/member number and this site does not collect cookies or information about you other than the information you opt to provide. To submit a Data Subject Access Request Form at any time, please submit the MyDataProtected DSAR form through your organization who will verify your SPID and transmit the DSAR directly to SafePorter.",
        'da': "SafePorter og websitet MyDataProtected har ikke adgang til dit navn, dine kontaktoplysninger eller dit medarbejder-/student-/medlemsnummer, og dette website indsamler ingen cookies eller oplysninger om dig ud over de oplysninger, som du selv vælger at angive. Hvis du ønsker at indsende en Data Subject Access Request-blanket (DSAR) kan du når som helst indsende MyDataProtecteds DSAR-blanket via din organisation, som verificerer dit SPID og videresender DSAR-blanketten direkte til SafePorter.",
        'de': "SafePorter und die MyDataProtected-Website haben keinen Zugriff auf Ihren Namen, Ihre Kontaktdaten und Ihre Personal-, Matrikel- oder Mitgliedsnummer. Diese Website erfasst keine Daten mithilfe von Cookies oder sonstige Informationen über Sie außer jene, die Sie uns zur Verfügung stellen. Sie können jederzeit eine Auskunft über Ihre gespeicherten personenbezogenen Daten beantragen. Reichen Sie dafür das MyDataProtected-Auskunftsformular über Ihre Organisation ein, diese verifiziert Ihre SafePorter ID und übermittelt das Auskunftsformular direkt an SafePorter.",
        'zh-cn': "SafePorter 和 MyDataProtected 网站无权访问您的姓名、联系信息或员工/学生/会员号码。除您选择提供的信息以外，本网站不会收集任何 cookie 或有关您的信息。如需提交数据主体访问请求（DSAR）表格，请通过您的组织提交 MyDataProtected DSAR 表格，您的组织将验证您的 SafePorterID，并将 DSAR 直接发送给 SafePorter。",
        'pt-br': "A SafePorter e o site MyDataProtected não têm acesso ao seu nome, contacto ou número de empregado/estudante/membro, e este site não recolhe cookies ou informação a seu respeito, com exceção da informação que opta por fornecer. Para enviar, a qualquer momento, um formulário de pedido de acesso ao titular dos dados (Data Subject Access Request Form, DSAR), por favor, envie o formulário DSAR do MyDataProtected através da sua organização, que verificará o seu SPID e que encaminhará o DSAR diretamente à SafePorter.",
        'fr': "SafePorter et le site MyDataProtected n'ont pas accès à votre nom, vos coordonnées ou votre numéro d'employé et ce site ne collecte pas de témoins (cookies) ou d'informations vous concernant autres que celles que vous choisissez de fournir. Pour soumettre un formulaire de demande d'accès aux données à tout moment, veuillez soumettre le formulaire DSAR de MyDataProtected par l'intermédiaire de votre organisation qui vérifiera votre SPID et transmettra le DSAR directement à SafePorter.",
        'es-pr': "SafePorter y el sitio MyDataProtected no tienen acceso a su nombre, información de contacto o número de empleado/estudiante/miembro, y este sitio no recopila cookies ni información suya que no sea la información que usted desee proporcionar. Para enviar un formulario de solicitud de acceso a los datos en cualquier momento, envíe el formulario DSAR MyDataProtected a través de su organización, quien verificará su SPID y transmitirá el DSAR directamente a SafePorter."

    },
    home: {
        'en-us': "Home",
        'es[pr': "Hogar"
    },
    engagement_survey: {
        'en-us': "Survey Portal",
        'es-pr': "Portal de pesquisa"
    },
    d_and_i_survey: {
        'en-us': "Diversity Identity Survey",
        'da': "SPØRGESKEMA OM MANGFOLDIGHEDSIDENTITET",
        'de': "UMFRAGE DIVERSITÄT & IDENTITÄT",
        'zh-cn': "多元化身份调查",
        'pt-br': "INQUÉRITO DE DIVERSIDADE DE IDENTIDADE",
        'fr': "ENQUÊTE SUR L'IDENTITÉ DE LA DIVERSITÉ",
        'es-pr': "Encuesta sobre Diversidad de Identidad"
    },
    other_surveys: {
        'en-us': "OTHER ASSIGNED SURVEYS",
        'da': "ANDRE TILDELTE UNDERSØGELSER",
        'de': "WEITERE UMFRAGEN FÜR SIE",
        'zh-cn': "其他指定调查",
        'pt-br': "OUTROS INQUÉRITOS ATRIBUÍDOS",
        'fr': "AUTRES ENQUÊTES ASSIGNÉES",
        'es-pr': "OTRAS ENCUESTAS ASIGNADAS"
    },
    all_surveys: {
        'en-us': "ALL AVAILABLE SURVEYS",
        'da': "ALLE TILGÆNGELIGE UNDERSØGELSER",
        'de': "ALLE VERFÜGBAREN UMFRAGEN",
        'zh-cn': "全部可选调查",
        'pt-br': "TODOS OS INQUÉRITOS DISPONÍVEIS",
        'fr': "TOUS LES ENQUÊTES DISPONIBLES",
        'es-pr': "TODAS LAS ENCUESTAS DISPONIBLES"
    },
    feedback_portal: {
        'en-us': "FEEDBACK PORTAL",
        'da': "FEEDBACKPORTAL",
        'de': "FEEDBACK-PORTAL",
        'zh-cn': "反馈门户",
        'pt-br': "PORTAL DE COMENTÁRIOS",
        'fr': "PORTAIL DE RÉTROACTION",
        'es-pr': "PORTAL DE RETROALIMENTACIÓN"
    },
    inclusion_feedback: {
        'en-us': "Inclusion Feedback",
        'es-pr': "Comentarios sobre la inclusión"
    },
    feedback_portal_disc: {
        'en-us': "PROVIDE SECURE INCLUSION FEEDBACK TO YOUR ORGANIZATION AT ANY TIME.",
        'da': "GIV DIN ORGANISATION SIKKER INKLUSIONSFEEDBACK NÅR SOM HELST.",
        'de': "GEBEN SIE IHRER ORGANISATION FEEDBACK ÜBER INKLUSION – JEDERZEIT UND SICHER.",
        'zh-cn': "随时向您的组织提供安全的包容性反馈。",
        'pt-br': "ENVIE, A QUALQUER MOMENTO E DE FORMA SEGURA, COMENTÁRIOS À SUA ORGANIZAÇÃO, NO QUE RESPEITA À INCLUSÃO.",
        'fr': "FOURNISSEZ DES COMMENTAIRES SUR L'INCLUSION SÉCURISÉE À VOTRE ORGANISATION À TOUT MOMENT.",
        'es-pr': "PROPORCIONE RETROALIMENTACIÓN SEGURA DE INCLUSIÓN A SU ORGANIZACIÓN EN CUALQUIER MOMENTO."
    },
    submit: {
        'en-us': "Submit",
        'da': "Send",
        'de': "Speichern",
        'zh-cn': "提交",
        'pt-br': "Enviar",
        'fr': "Soumettre",
        'es-pr': "Enviar"
    },
    cancel: {
        'en-us': "Cancel",
        'da': "Annuller",
        'de': "Abbrechen",
        'zh-cn': "取消",
        'pt-br': "Cancelar",
        'fr': "Annuler",
        'es-pr': "Cancelar"
    },
    yes_i_confirm: {
        'en-us': "YES, I CONFIRM",
        'da': "JA, JEG BEKRÆFTER",
        'de': "BESTÄTIGEN",
        'zh-cn': "是的，我确认",
        'pt-br': "SIM, CONFIRMO",
        'fr': "OUI, JE CONFIRME",
        'es-pr': "SÍ, LO CONFIRMO"
    },
    thank_you: {
        'en-us': "MyDataProtected Portal",
        'da': "Tak for din deltagelse",
        'de': "Vielen Dank für Ihre Teilnahme!",
        'zh-cn': "感谢您的参与",
        'pt-br': "Obrigado pela sua participação",
        'fr': "Merci d'avoir participé",
        'es-pr': "Portal de MyDataProtected"
    },
    please_all: {
        'en-us': "Please complete all sections to submit the survey.",
        'da': "Du skal udfylde alle afsnit for at kunne indsende undersøgelsen.",
        'de': "Füllen Sie bitte alle Abschnitte aus, bevor Sie die Umfrage speichern.",
        'zh-cn': "请填写所有部分以提交调查。",
        'pt-br': "Por favor, preencha todas as secções para enviar o inquérito.",
        'fr': "Veuillez remplir toutes les sections pour soumettre le sondage.",
        'es-pr': "Por favor complete todas las secciones para enviar la encuesta."

    },
    please_one: {
        'en-us': "Please complete at least one of the sections to submit the survey.",
        'da': "Du skal udfylde mindst et af afsnittene for at kunne indsende undersøgelsen.",
        'de': "Füllen Sie bitte mindestens einen Abschnitt aus, bevor Sie die Umfrage speichern.",
        'zh-cn': "请至少完成其中一个部分，以提交调查。",
        'pt-br': "Por favor, preencha pelo menos uma das secções para enviar o inquérito.",
        'fr': "Veuillez remplir au moins une des sections pour soumettre le sondage.",
        'es-pr': "Complete al menos una de las secciones para enviar la encuesta."
    },
    error_submit_form: {
        'en-us': "Error submitting form",
        'da': "Fejl ved indsendelse af blanket",
        'de': "Fehler melden",
        'zh-cn': "提交表格出现错误",
        'pt-br': "Erro no envio do formulário",
        'fr': "Erreur lors de l'envoi du formulaire",
        'es-pr': "Error al enviar el formulario"
    },
    if_you_wish_update: {
        'en-us': "If you wish to update your answers to these questions, you can use your SafePorter ID to submit updated information.",
        'da': "Hvis du vil opdatere dine svar på disse spørgsmål, kan du bruge dit SafePorter ID tIl at indsende opdaterede oplysninger.",
        'de': "Wenn Sie Ihre Antworten zu diesen Fragen ändern wollen, können Sie diese mit Ihrer SafePorter ID bearbeiten und speichern.",
        'zh-cn': "如果您希望修改这些问题的回答，您可以使用您的 SafePorter ID 来提交更改后的信息。",
        'pt-br': "Se quiser atualizar as suas respostas a estas perguntas, pode utilizar o seu SafePorter ID para inserir informações atualizadas.",
        'fr': "Si vous souhaitez mettre à jour vos réponses à ces questions, vous pouvez utiliser votre ID SafePorter pour soumettre des informations mises à jour.",
        'es-pr': "Si desea actualizar sus respuestas a estas preguntas, puede usar su ID de SafePorter para enviar información actualizada."
    },
    next: {
        'en-us': "Next",
        'da': "Næste",
        'de': "Weiter",
        'zh-cn': "下一个",
        'pt-br': "Seguinte",
        'fr': "Suivant",
        'es-pr': "Siguiente"
    },
    you_can_overwrite: {
        'en-us': "You can overwrite and change any element of the survey easily:",
        'da': "Du kan nemt overskrive og ændre en hvilken som helst del af undersøgelsen:",
        'de': "Sie können jede Antwort in der Umfrage einfach ändern:",
        'zh-cn': "您可以轻松地覆盖、更改调查中的任何元素：",
        'pt-br': "Pode substituir e alterar as informações em qualquer parte do inquérito facilmente:",
        'fr': "Vous pouvez écraser et modifier facilement n'importe quel élément de l'enquête :",
        'es-pr': "Puede sobrescribir y cambiar cualquier elemento de la encuesta fácilmente:"

    },
    ty_1: {
        'en-us': "1) Go to the section(s) you wish to submit a new or revised answer for.",
        'da': "1) Gå til det eller de afsnit, som du vil indsende et nyt eller ændret svar for.",
        'de': "1) Gehen Sie zum Abschnitt, in dem Sie eine Antwort ändern oder eine neue Antwort speichern wollen.",
        'zh-cn': "1) 前往您想要提交新回答或更改回答的部分。",
        'pt-br': "1) Vá até à(s) secção(ões) na(s) qual(ais) pretende inserir uma resposta nova ou atualizada.",
        'fr': "1) Accédez à la ou aux sections pour lesquelles vous souhaitez soumettre une réponse nouvelle ou révisée.",
        'es-pr': "1) Vaya a la(s) sección(es) para la(s) que desea enviar una respuesta nueva o revisada."
    },
    ty_2: {
        'en-us': "2) Select or input the full answer that best represents you.",
        'da': "2) Vælg eller skriv det fuldstændige svar, der repræsenterer dig så godt som muligt.",
        'de': "2) Wählen oder geben Sie die Antwort, die Sie am besten beschreibt.",
        'zh-cn': "2) 选择或输入最能代表您的完整答案。",
        'pt-br': "2) Selecione ou insira a resposta completa que melhor representa a sua circunstância pessoal.",
        'fr': "2) Sélectionnez ou entrez la réponse complète qui vous représente le mieux.",
        'es-pr': "2) Seleccione o ingrese la respuesta completa que mejor lo represente."
    },
    ty_3: {
        'en-us': "3) Scroll to the bottom of the survey and hit SUBMIT.",
        'da': "3) Scroll ned til bunden af undersøgelsen, og klik på SEND.",
        'de': "3) Scrollen Sie zum Ende der Umfrage und klicken Sie auf SPEICHERN.",
        'zh-cn': "3) 滚动页面至调查最底部，并点击“提交”。",
        'pt-br': "3) Vá até ao final do inquérito e clique em ENVIAR.",
        'fr': "3) Faites défiler l'enquête jusqu'en bas et appuyez sur SOUMETTRE.",
        'es-pr': "3) Desplázate hasta la parte inferior de la encuesta y presiona ENVIAR."
    },
    any_prior: {
        'en-us': "Any prior answer you have submitted for that section will be overwritten.",
        'da': "Alle svar på det afsnit, som du har indsendt tidligere, vil blive overskrevet.",
        'de': "Alle früher gespeicherten Antworten in diesem Abschnitt werden überschrieben.",
        'zh-cn': "您之前在此部分所提交的任何回答都将被新回答覆盖。",
        'pt-br': "Qualquer resposta previamente inserida na secção em questão será substituída.",
        'fr': "Toute réponse antérieure que vous avez soumise pour cette section sera écrasée.",
        'es-pr': "Cualquier respuesta previa que haya enviado para esa sección se sobrescribirá."
    },
    we_have_no_access: {
        'en-us': "SafePorter and the MyDataProtected site do not have any access to your name, contact information or employee/student/member number and this site does not collect cookies or information about you other than the information you opt to provide. To submit a Data Subject Access Request Form at any time, please submit the MyDataProtected DSAR form through your organization who will verify your SPID and transmit the DSAR directly to SafePorter.",
        'da': "SafePorter og websitet MyDataProtected har ikke adgang til dit navn, dine kontaktoplysninger eller dit medarbejder-/student-/medlemsnummer, og dette website indsamler ingen cookies eller oplysninger om dig ud over de oplysninger, som du selv vælger at angive. Hvis du ønsker at indsende en Data Subject Access Request-blanket (DSAR) kan du når som helst indsende MyDataProtecteds DSAR-blanket via din organisation, som verificerer dit SPID og videresender DSAR-blanketten direkte til SafePorter.",
        'de': "SafePorter und die MyDataProtected-Website haben keinen Zugriff auf Ihren Namen, Ihre Kontaktdaten und Ihre Personal-, Matrikel- oder Mitgliedsnummer. Diese Website erfasst keine Daten mithilfe von Cookies oder sonstige Informationen über Sie außer jene, die Sie uns zur Verfügung stellen. Sie können jederzeit eine Auskunft über Ihre gespeicherten personenbezogenen Daten beantragen. Reichen Sie dafür das MyDataProtected-Auskunftsformular über Ihre Organisation ein, diese verifiziert Ihre SafePorter ID und übermittelt das Auskunftsformular direkt an SafePorter.",
        'zh-cn': "SafePorter 和 MyDataProtected 网站无权访问您的姓名、联系信息或员工/学生/会员号码。除您选择提供的信息以外，本网站不会收集任何 cookie 或有关您的信息。如需提交数据主体访问请求（DSAR）表格，请通过您的组织提交 MyDataProtected DSAR 表格，您的组织将验证您的 SafePorterID，并将 DSAR 直接发送给 SafePorter。",
        'pt-br': "A SafePorter e o site MyDataProtected não têm acesso ao seu nome, contacto ou número de empregado/estudante/membro, e este site não recolhe cookies ou informação a seu respeito, com exceção da informação que opta for fornecer. Para enviar, a qualquer momento um formulário de pedido de acesso ao titular dos dados (Data Subject Access Request Form, DSAR), por favor, envie o formulário DSAR do MyDataProtected através da sua organização, que verificará o seu SPID e que encaminhará o DSAR diretamente à SafePorter.",
        'fr': "SafePorter et le site MyDataProtected n'ont aucun accès à votre nom, vos coordonnées ou votre numéro d'employé/d'étudiant/de membre et ce site ne collecte pas de cookies ou d'informations vous concernant autres que les informations que vous choisissez de fournir. Pour soumettre un formulaire de demande d'accès aux données personnelles à tout moment, veuillez soumettre le formulaire DSAR MyDataProtected via votre organisation qui vérifiera votre SPID et transmettra le DSAR directement à SafePorter.",
        'es-pr': "SafePorter y el sitio MyDataProtected no tienen acceso a su nombre, información de contacto o número de empleado/estudiante/miembro, y este sitio no recopila cookies ni información suya que no sea la información que usted desee proporcionar. Para enviar un formulario de solicitud de acceso a los datos en cualquier momento, envíe el formulario DSAR MyDataProtected a través de su organización, que verificará su SPID y transmitirá el DSAR directamente a SafePorter."
    },
    to_feedback: {
        'en-us': "Take me to my organization's Feedback Portal",
        'da': "Før mig til min organisations Feedbackportal",
        'de': "Zum Feedback-Portal meiner Organisation",
        'zh-cn': "带我前往我的组织的反馈门户",
        'pt-br': "Ir para o portal de comentários da minha organização",
        'fr': "Emmenez-moi au portail de commentaires de mon organisation",
        'es-pr': "Llévame al portal de comentarios de mi organización"
    },
    ty_for_feedback: {
        'en-us': "Thank you for submitting feedback to your organization.",
        'da': "Tak for din feedback til din organisation.",
        'de': "Vielen Dank, dass Sie Ihrer Organisation Feedback gegeben haben.",
        'zh-cn': "感谢您向您的组织提交反馈。",
        'pt-br': "Obrigado pelo envio de comentários à sua organização.",
        'fr': "Merci d'avoir soumis vos commentaires à votre organisation.",
        'es-pr': "Gracias por enviar sus comentarios a su organización."
    },
    your_id: {
        'en-us': "Your SafePorter ID is:",
        'da': "Dit SafePorter ID er:",
        'de': "Ihre SafePorter ID:",
        'zh-cn': "您的 SafePorter ID 是：",
        'pt-br': "O seu SafePorter ID é:",
        'fr': "Votre identifiant SafePorter est :",
        'es-pr': ""
    },
    a_problem: {
        'en-us': "",
        'da': "Der opstod et problem i forbindelse med behandlingen af dit svar. Vi beklager ulejligheden. Husk dit SafePorter ID, og prøv igen senere.",
        'de': "Bei der Verarbeitung ist ein Problem aufgetreten. Wir entschuldigen uns für die Unannehmlichkeiten. Bitte bewahren Sie Ihre SafePorter ID auf und versuchen Sie es zu einem späteren Zeitpunkt erneut.",
        'zh-cn': "网站在处理您的回应时出现问题。我们为给您所带来的不便深表歉意。请记录好您的 SafePorter ID，以便在未来再次尝试。",
        'pt-br': "Ocorreu um erro ao processar a sua resposta. Pedimos desculpa pelo inconveniente. Por favor, tome nota do seu SafePorter ID e tente novamente mais tarde.",
        'fr': "",
        'es-pr': ""
    },
    not_at_this_time: {
        'en-us': "MyDataProtected Portal.",
        'da': "Vi kan ikke tage imod dit svar lige nu.",
        'de': "Wir können Ihre Anfrage derzeit leider nicht verarbeiten.",
        'zh-cn': "我们目前无法接受您的回应。",
        'pt-br': "De momento, não podemos aceitar a sua resposta.",
        'fr': "Merci d'avoir participé",
        'es-pr': "Portal MyDataProtected."
    },
    i_confirm: {
        'en-us': "I confirm that I want all information previously submitted to be deleted. This information will be deleted upon confirmation.",
        'da': "Jeg bekræfter, at jeg ønsker at få samtlige tidligere indsendte oplysninger slettet. Disse oplysninger vil blive slettet, efter at du har bekræftet.",
        'de': "Ja, ich möchte alle bisher gespeicherten Informationen löschen. Die Informationen werden nach Ihrer Bestätigung gelöscht.",
        'zh-cn': "我在此确认，我想删除之前提交的所有信息。这些信息将在确认后删除。",
        'pt-br': "Confirmo que pretendo eliminar toda a informação previamente fornecida. Esta informação será eliminada mediante confirmação.",
        'fr': "Je confirme que je souhaite que toutes les informations soumises précédemment soient supprimées.Ces informations seront supprimées lors de la confirmation.",
        'es-pr': "Confirmo que quiero eliminar toda la información enviada anteriormente. Esta información se eliminará tras la confirmación."
    },
    strongly_disagree: {
        'en-us': "Strongly disagree",
        'da': "Helt uenig",
        'de': "Stimme überhaupt nicht zu",
        'zh-cn': "强烈不同意",
        'pt-br': "Discordo totalmente",
        'fr': "Tout à fait d'accord",
        'es-pr': "Totalmente en desacuerdo"
    },
    disagree: {
        'en-us': "Disagree",
        'da': "Uenig",
        'de': "Stimme nicht zu",
        'zh-cn': "不同意",
        'pt-br': "Discordo",
        'fr': "Être en désaccord",
        'es-pr': "No estoy de acuerdo"
    },
    neutral: {
        'en-us': "Neutral",
        'da': "Neutral",
        'de': "Weder noch",
        'zh-cn': "中立",
        'pt-br': "Não concordo nem discordo",
        'fr': "Neutre",
        'es-pr': "Neutral"
    },
    agree: {
        'en-us': "Agree",
        'da': "Enig",
        'de': "Stimme zu",
        'zh-cn': "同意",
        'pt-br': "Concordo",
        'fr': "d'accord",
        'es-pr': "Estpy de acuerdo"
    },
    strongly_agree: {
        'en-us': "Strongly Agree",
        'da': "Helt enig",
        'de': "Stimme voll und ganz zu",
        'zh-cn': "强烈同意",
        'pt-br': "Concordo totalmente",
        'fr': "Tout à fait d'accord",
        'es-pr': "Totalmente de acuerdo"
    },
    org_no_access: {
        'en-us': "Your organization will not have access to or see specific information about your feedback or you, only aggregated statistics or distilled insights.",
        'da': "Din organisation har ikke mulighed for at se specifikke oplysninger om din feedback eller dig, men kun aggregerede oplysninger i form af statistik eller sammenfattende konklusioner.",
        'de': "Ihre Organisation hat keinen Zugriff auf konkrete Details Ihres Feedbacks oder über Sie, sondern sie erhält nur Gesamtstatistiken und anonymisierte Ergebnisse.",
        'zh-cn': "您的组织将无法访问或查看有关您的反馈或您本人的具体信息，他们只可访问汇总后的数据或提炼后的见解。",
        'pt-br': "A sua organização não poderá visualizar ou aceder a informações específicas sobre os seus comentários ou sobre si, tendo apenas acesso a dados estatísticos agregados e a análises condensadas de dados.",
        'fr': "Votre organisation n'aura pas accès ou ne verra pas d'informations spécifiques sur vos commentaires ou sur vous, uniquement des statistiques agrégées ou des informations distillées.",
        'es-pr': "Su organización no tendrá acceso ni verá información específica sobre sus comentarios o sobre usted, solo estadísticas agregadas o información destilada."
    },
    i_opt_in: {
        'en-us': "I consent to allow my diversity survey information to accompany this feedback submission.",
        'da': "Jeg giver mit samtykke til, at oplysningerne fra min mangfoldighedsundersøgelse må indsendes sammen med min feedback.",
        'de': "Ich bin damit einverstanden, dass meine Daten aus der Diversitäts-Umfrage mit diesem Feedback verknüpft werden.",
        'zh-cn': "我选择同意将我的多元化调查信息与这份反馈一并提交。",
        'pt-br': "Permito que os dados do meu inquérito de diversidade sejam enviados em conjunto com os meus comentários.",
        'fr': "J'accepte d'autoriser les informations de mon enquête sur la diversité à accompagner cette soumission de commentaires.",
        'es-pr': "Doy mi consentimiento para permitir que la información de mi encuesta de diversidad acompañe este envío de comentarios."
    },
    not_opt_in: {
        'en-us': "I do not want any diversity identity data to accompany this feedback submission.",
        'da': "Jeg ønsker ikke, at mine oplysninger om mangfoldighedsidentitet skal indsendes sammen med min feedback.",
        'de': "Ich möchte nicht, dass meine Diversitäts-Daten mit diesem Feedback verknüpft werden.",
        'zh-cn': "在提交这份反馈时，我不希望一并提交任何多元化身份数据。",
        'pt-br': "Não quero que nenhum dos meus dados relativos à diversidade de identidade sejam enviados em conjunto com os meus comentários.",
        'fr': "Je ne veux pas que des données sur l'identité de la diversité accompagnent cette soumission de commentaires.",
        'es-pr': "No quiero que ningún dato de identidad de diversidad acompañe a este envío de comentarios."
    },
    prefer_no_answer: {
        'en-us': "I prefer not to answer",
        'fr-ca': "Je préfère ne pas répondre",
        'pt-br': "à Prefiro não responder",
        'es-pr': "Prefiero no contestar"
    },
    language: {
        'en-us': "Language",
        'da': "Sprog",
        'de': "Sprache",
        'zh-cn': "语言 ",
        'pt-br': "Idioma",
        'fr-ca': "Langue",
        'es-pr': "Idioma"
    },
    tmd_welcome: {
        'en-us': "Welcome to",
        'pt-br': "Bem-vindo à",
        'fr': "Bienvenue à",
        'es-pr': "Bienvenidos a"
    },
    tmd_your: {
        'en-us': "Your",
        'pt-br': "Seu",
        'fr': "Votre",
        'es-pr': "Tu"
    },
    tmd_has_been: {
        'en-us': "has been associated with the following information.",
        'pt-br': "foi associado com as seguintes informações.",
        'fr': "a été associé aux informations suivantes.",
        'es-pr': "se ha asociado con la siguiente información."
    },
    tmd_i_consent: {
        'en-us': "I consent to allow this information to accompany my survey responses in the de-identified aggregate where I cannot be identified from its inclusion.",
        'pt-br': "Eu concordo que essas informações acompanhem minhas informações da pesquisa de diversidade de identidade na amostra global",
        'fr': "Je consens à ce que ces informations accompagnent les informations de mon enquête sur l'identité de la diversité dans l'ensemble",
        'es-pr': "Doy mi consentimiento para permitir que esta información acompañe a mis respuestas a la encuesta en el agregado anonimizado donde no pueda ser identificado a partir de su inclusión."
    },
    tmd_i_dont_consent: {
        'en-us': "I do not want this information to be tied to my survey responses even in the de-identified aggregate",
        'pt-br': "Não quero que minhas informações de diversidade de identidade sejam vinculadas a essas informações, mesmo na amostra global sem identificação pessoal",
        'fr': "Je ne veux pas que mes informations d'identité sur la diversité soient liées à ces informations, même dans l'ensemble anonymisé",
        'es-pr': "No quiero que esta información esté vinculada a mis respuestas a la encuesta, ni siquiera en el agregado anonimizado"
    },
    tmd_no_access: {
        'en-us': "Your organization never has access to your data except as it rolls up into larger percentages or total numbers across the company.",
        'pt-br': "Sua organização nunca tem acesso aos seus dados, exceto quando eles se acumulam em porcentagens maiores ou números totais da empresa.",
        'fr': "Votre organisation n'a jamais accès à vos données, sauf lorsqu'elles sont cumulées en pourcentages plus importants ou en nombres totaux dans l'ensemble de l'entreprise.",
        'es-pr': "Su organización nunca tiene acceso a sus datos, excepto cuando se acumulan en porcentajes más grandes o números totales a través la empresa."
    },
    tmd_still_no_consent: {
        'en-us': "I understand this but I do not want to let this information accompany my Diversity Identity Data. I do not consent.",
        'pt-br': "Entendi isso, mas não quero que essas informações acompanhem meus dados de diversidade de identidade. Não concordo.",
        'fr': "Je comprends cela, mais je ne veux pas que ces informations accompagnent mes données d'identité sur la diversité. Je ne consens pas.",
        'es-pr': "Entiendo esto, pero no quiero permitir que esta información acompañe a mis Datos de Identidad de Diversidad. No lo consiento."
    },
    tmd_ok_consent: {
        'en-us': "I understand this and I do consent for this information to accompany my survey information.",
        'pt-br': "Entendi isso e concordo que essas informações acompanhem as informações da minha pesquisa.",
        'fr': "Je comprends cela et je consens à ce que ces informations accompagnent les informations de mon enquête. ",
        'es-pr': "Entiendo esto y doy mi consentimiento para que esta información acompañe a la información de mi encuesta."
    },
    delete_my_info: {
        'en-us': "Delete my information",
        'pt-br': "Excluir minhas informações",
        'fr': "Supprimer mes informations",
        'es-pr': "Eliminar mi información"
    },
    inc_i_have_witnessed: {
        'en-us': "I have witnessed an accessibility issue",
        'pt-br': "Testemunhei um problema de acessibilidade",
        'es-pr': "He sido testigo de un problema de accesibilidad"
    },
    inc_i_was_affected: {
        'en-us': "I was affected by an accessibility issue",
        'pt-br': "Fui afetado por um problema de acessibilidade",
        'es-pr': "Me afectó un problema de accesibilidad"
    },
    inc_neither: {
        'en-us': "Neither",
        'pt-br': "Nenhuma destas opções",
        'es-pr': "ninguno"
    },
    welcome_back: {
        'en-us': "WELCOME BACK",
        'pt-br': "BEM-VINDO DE VOLTA",
        'es-pr': "BIENVENIDO DE NUEVO"
    },
    last_submitted: {
        'en-us': "Your Diversity Identity Data Survey was last submitted or updated on:",
        'pt-br': "Sua pesquisa de diversidade de identidade foi enviada ou atualizada pela última vez em:",
        'es-pr': "Enviado por última vez el"
    },
    please_indicate: {
        'en-us': "Please indicate which action you want to take:",
        'pt-br': "Indique a ação que deseja realizar:",
        'es-pr': "Indique qué acción desea realizar:"
    },
    make_changes: {
        'en-us': "MAKE CHANGES TO MY",
        'pt-br': "FAZER ALTERAÇÕES NA MINHA",
        'es-pr': "REALIZAR CAMBIOS EN MI"
    },
    div_ident_survey: {
        'en-us': "DIVERSITY IDENTITY SURVEY",
        'pt-br': "PESQUISA DE DIVERSIDADE DE IDENTIDADE",
        'es-pr': "ENCUESTA DE IDENTIDAD DE DIVERSIDAD"
    },
    update_info: {
        'en-us': "UPDATE MY INFORMATION",
        'pt-br': "ATUALIZAR MINHAS INFORMAÇÕES",
        'es-pr': "ACTUALIZAR MI INFORMACIÓN"
    },
    not_full_survey: {
        'en-us': "You do not need to complete your full survey again to update your Diversity Identity Data. You can go directly to the section(s) you wish to update or change.",
        'pt-br': "Você não precisa preencher sua pesquisa novamente desde o início para atualizar seus dados de diversidade de identidade. Vá diretamente para a seção ou seções que você deseja atualizar ou alterar.",
        'es-pr': "No es necesario que vuelva a completar la encuesta completa para actualizar sus datos de identidad de diversidad. Puede ir directamente a la(s) sección(es) que desea actualizar o cambiar."
    },
    same_answers: {
        'en-us': "Any information you submit will override your prior answer for that section.",
        'pt-br': "Qualquer informação que você enviar substituirá sua resposta anterior para a seção.",
        'es-pr': "Cualquier información que envíe anulará su respuesta anterior para esa sección."
    },
    delete_my: {
        'en-us': "DELETE MY",
        'pt-br': "EXCLUIR MEUS",
        'es-pr': "BORRAR MIS"
    },
    d_and_i_data: {
        'en-us': "DIVERSITY IDENTITY DATA",
        'pt-br': "DADOS DE DIVERSIDADE DE IDENTIDADE",
        'es-pr': "DATOS DE IDENTIDAD DE DIVERSIDAD"
    },
    select_all_that_apply: {
        'en-us': "Select all that apply",
        'pt-br': "Selecione todas as opções que se aplicam",
        'es-pr': "Seleccione todas las que correspondan"
    },
    select_one: {
        'en-us': "Select one",
        'pt-br': "Selecione um",
        'es-pr': "Seleccione uno"
    },
    whitnessed: {
        'en-us': "I witnessed something I perceived as bias",
        'pt-br': "Eu testemunhei algo que percebi como preconceito",
        'es-pr': "Fui testigo de algo que percibí como prejuicios"
    },
    affected: {
        'en-us': "I was directly affected by bias",
        'pt-br': "Fui diretamente afetado pelo preconceito",
        'es-pr': "Me afectó directamente el sesgo"
    },
    neither: {
        'en-us': "Neither",
        'pt-br': "Nem",
        'es-pr': "NINGUNO(A)"
    },
    last_submitted: {
        'en-us': "Last submitted on",
        'pt-br': "Último enviado em",
        'es-pr': ""
    },
    terms_of_use: {
        'en-us': "By clicking \"Submit ID\", you acknowledge and accept the information set out in our",
        'pt-br': "Ao clicar em \"Submeter SafePorter ID\", você reconhece e aceita as informações estabelecidas em nosso",
        'es-pr': "Al hacer clic en \"Enviar ID\", usted reconoce y acepta la información establecida en nuestro"
    },
    privacy_notice: {
        'en-us': "Privacy Policy",
        'pt-br': "Política de Privacidade",
        'es-pr': "Política de privacidad"
    },
    delete_all: {
        'en-us': "Delete all information?",
        'pt-br': "Excluir todas as informações?",
        'es-pr': "¿Eliminar toda la información?"
    },
    confirm_delete: {
        'en-us': "I confirm that I want my Diversity Identity data to be deleted",
        'pt-br': "Confirmo que quero que meus dados de identidade de diversidade sejam excluídos",
        'es-pr': "Confirmo que quiero que se eliminen mis datos de identidad de diversidad"
    },
    return_to_update: {
        'en-us': "Return to Update My Survey",
        'pt-br': "Voltar para atualizar minha pesquisa",
        'es-pr': "Volver a Actualizar mi encuesta"
    },
    welcome_landing_heading_1: {
        'en-us': "Your privacy is paramount.",
        'es-pr': "Valoramos mucho su privacidad y sus comentarios."
    },
    welcome_landing_heading_2: {
        'en-us': "  Step inside and let your voice be heard.",
        'es-pr': "Entra y deja que se escuche tu voz."
    },
    inclusion_feedback_portal: {
        'en-us': "Inclusion Feedback Portal",
        'es-pr': "Portal de comentarios sobre inclusión"
    },
    inclusion_feedback_portal_card_heading: {
        'en-us': "Contribute to fostering a welcoming and inclusive environment by sharing your valuable feedback.",
        'es-pr': "Contribuya a fomentar un entorno acogedor e inclusivo compartiendo sus valiosos comentarios"
    },
    inclusion_feedback_button: {
        'en-us': "Visit Inclusion Feedback",
        'es-pr': "Visite Comentarios sobre la inclusión"
    },
    diversity_identity_survey_portal: {
        'en-us': "Diversity Identity Survey",
        'es-pr': "Encuesta de Identidad de Diversidad"
    },
    diversity_identity_survey_card_heading: {
        'en-us': "Please help us understand our overall representation across different backgrounds, cultures, abilities, and identity characteristics.",
        'es-pr': "Por favor, ayúdenos a comprender nuestra representación general a través de diferentes orígenes, culturas, habilidades y características de identidad."
    },
    diversity_identity_survey_button: {
        'en-us': "Visit Diversity Identity Survey",
        'es-pr': "Visite la Encuesta de Identidad de Diversidad"
    },
    engagement_survey_portal: {
        'en-us': "Survey Portal",
        'es-pr': "Portal de pesquisa"
    },
    engagement_survey_card_heading: {
        'en-us': "Help us understand your satisfaction, commitment, and level of engagement as valued employees.",
        'es-pr': "Ayúdenos a comprender su nivel de satisfacción, compromiso y nivel de interacción como empleados valiosos."
    },
    engagement_no_data: {
        'en-us': "No Categories Available",
        'es-pr': "No hay categorías disponibles"
    },
    your_completed_survey: {
        "en-us": "Your Completed Survey(s)",
        'es-pr': "Su(s) encuesta(s) completada(s)"
    },
    your_new_survey: {
        "en-us": "Your New Available Survey(s)",
        'es-pr': "Su(s) nueva(s) encuesta(s) disponible(s)"
    },
    visit_engagement_survey_portal_button: {
        'en-us': "Visit My Survey Portal",
        'es-pr': "Visite el portal de participación"
    },
    not_complete_survey_available: {
        "en-us": "No feedback surveys completed yet",
        'es-pr': "Todavía no se han completado las encuestas de retroalimentación"
    },
    not_new_survey_available: {
        "en-us": "No feedback surveys available yet",
        'es-pr': "Todavía no hay encuestas de retroalimentación disponibles"
    },
    assigned_on: {
        "en-us": "Assigned on",
        'es-pr': "Asignado el"
    },
    completed_on: {
        "en-us": "Completed on",
        'es-pr': "Completado el"
    },
    inclusion_feedback_error: {
        "en-us": "Please select an consent include",
        'es-pr': "Indique si acepta o no permitir que sus datos de diversidad acompañen a sus respuestas en conjunto"
    },
    engagement_survey_portal_error: {
        "en-us": "Please select an consent include",
        'es-pr': "Indique si acepta o no permitir que sus datos de diversidad acompañen a sus respuestas en conjunto"
    },
    last_modified_on: {
        "en-us": "Last modified on",
        'es-pr': "Última modificación el"
    }
}