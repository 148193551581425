import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import DocumentTitle from "react-document-title";
import CssBaseline from "@material-ui/core/CssBaseline";
// import reactAxe from "@axe-core/react";

import theme from "./App.theme";
import store from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// if (process.env.NODE_ENV !== "production") {
//   reactAxe(React, ReactDOM, 1000);
// }

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <DocumentTitle title="DataProtected by SafePorter">
            <App />
          </DocumentTitle>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
